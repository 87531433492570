import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';

import IlluminateBundleVersion from './IlluminateBundleVersion';
import IlluminatePacks from './IlluminatePacks';
import { BundleContext } from './context/BundleContext';
import PackProvider from './context/PackContext';

const IlluminatePage = ({ goToInstall }: { goToInstall: () => void }) => {
  const { bundleLoading, bundleVersion } = useContext(BundleContext);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);

  useEffect(() => {
    setShowSpinner(true);

    if (!bundleLoading) {
      if (bundleVersion) {
        setShowSpinner(false);
      } else {
        goToInstall();
      }
    }
  }, [goToInstall, bundleLoading, bundleVersion]);

  if (showSpinner) {
    return <Spinner text="Loading Illuminate Configuration" />;
  }

  return (

    <DocumentTitle title="Illuminate Configuration">
      <PageHeader title="Illuminate Processing Packs">
        <span>
          Manage which Illuminate Packs you wish to use
        </span>
        <>
          Learn more about Illuminate in the <a href="https://docs.graylog.org/docs/illuminate-installer">documentation</a>.
        </>
        <IlluminateBundleVersion />

      </PageHeader>

      <PackProvider>
        <IlluminatePacks />
      </PackProvider>

    </DocumentTitle>
  );
};

export default IlluminatePage;
