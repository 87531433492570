import React, { useEffect, useRef } from 'react';

import { LinkContainer } from 'components/graylog/router';
import DocsHelper from 'util/DocsHelper';
import Routes from 'routing/Routes';
import { Button, Row, Col } from 'components/graylog';
import { DocumentTitle, ExternalLinkButton, PageHeader, Spinner, IfPermitted } from 'components/common';
import ArchiveActions from 'archive/ArchiveActions';
import ArchiveStore, { ArchiveStoreState, DEFAULT_PAGE_SIZE } from 'archive/ArchiveStore';
import ArchiveLicenseActions from 'archive/ArchiveLicenseActions';
import ArchiveLicenseStore from 'archive/ArchiveLicenseStore';
import ArchiveCatalog from 'archive/components/ArchiveCatalog';
import CombinedProvider from 'injection/CombinedProvider';
import connect from 'stores/connect';
import ArchiveCreationSection from 'archive/components/ArchiveCreationSection';
import ArchiveSystemJobs from 'archive/components/ArchiveSystemJobs';
import type { Catalog, Jobs, LicenseStatus } from 'archive/types';
import type { Indices } from 'stores/indices/IndicesStore';
import ArchivePageHeaderSupport from 'archive/components/ArchivePageHeaderSupport';
import { Store } from 'stores/StoreTypes';
import { CatalogPropType, IndicesPropType, JobsPropType, LicenseStatusPropType } from 'archive/propTypes';

const { IndicesStore, IndicesActions } = CombinedProvider.get('Indices');
const { SystemJobsStore, SystemJobsActions } = CombinedProvider.get('SystemJobs');

type Props = {
  catalog: Catalog,
  jobs: Jobs,
  indices: Indices,
  licenseStatus: LicenseStatus,
};

const ArchivePage = ({ catalog, jobs, indices, licenseStatus }: Props) => {
  const timerId = useRef<number | undefined>();
  const shortTimerId = useRef<number | undefined>();
  const timerInterval = 5000;
  const { pagination: { page, per_page: perPage, query } } = catalog;
  const shortTimerInterval = 2000;

  useEffect(() => {
    ArchiveLicenseActions.getLicenseStatus();
    IndicesActions.listAll();
    SystemJobsActions.list();
  }, []);

  useEffect(() => {
    ArchiveActions.searchPaginated(page, perPage, query);

    shortTimerId.current = window.setInterval(() => {
      SystemJobsActions.list();
    }, shortTimerInterval);

    timerId.current = window.setInterval(() => {
      ArchiveActions.searchPaginated(page, perPage, query);
      IndicesActions.listAll();
    }, timerInterval);

    return () => {
      clearInterval(timerId.current);
      clearInterval(shortTimerId.current);
      timerId.current = undefined;
      shortTimerId.current = undefined;
    };
  }, [page, perPage, query]);

  const { archives, archivesContext, backendsContext, pagination } = catalog;

  return (
    <DocumentTitle title="Archives">
      <span>
        <PageHeader title="Archives">
          <span>
            The Graylog archive feature allows you to create archives from indices. The generated archives
            are simple flat files that can be moved to cheap storage and re-imported at any time.
          </span>
          <ArchivePageHeaderSupport />
          <span>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
              <Button bsStyle="info" className="active">Overview</Button>
            </LinkContainer>
              &nbsp;
            <IfPermitted permissions="archiveconfig:update">
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
                <Button bsStyle="info">Configuration</Button>
              </LinkContainer>
                &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
                <Button bsStyle="info">Manage Backends</Button>
              </LinkContainer>
                &nbsp;
            </IfPermitted>
          </span>
        </PageHeader>
        <ArchiveCreationSection indices={indices} licenseStatus={licenseStatus} />
        <ArchiveSystemJobs jobs={jobs} catalog={catalog} />
        <Row className="content">
          <Col md={12}>
            <div className="pull-right">
              <IfPermitted permissions="archivecatalog:rebuild">
                <Button bsStyle="info" onClick={() => { ArchiveActions.rebuildCatalog(); }}>Rebuild Catalog</Button>
                &nbsp;
              </IfPermitted>
              <ExternalLinkButton bsStyle="info" href={DocsHelper.toString('archiving.html')}>
                Archive documentation
              </ExternalLinkButton>
            </div>
            {
              (!archives) ? <Spinner text="Loading archive catalog..." />
                : (
                  <ArchiveCatalog archives={archives}
                                  archivesContext={archivesContext}
                                  backendsContext={backendsContext}
                                  pagination={pagination} />
                )
            }
          </Col>
        </Row>
      </span>
    </DocumentTitle>
  );
};

ArchivePage.propTypes = {
  catalog: CatalogPropType,
  jobs: JobsPropType,
  indices: IndicesPropType,
  licenseStatus: LicenseStatusPropType,
};

ArchivePage.defaultProps = {
  catalog: {
    archives: undefined,
    archivesContext: {},
    backendsContext: {},
    pagination: {
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      query: '',
      count: 0,
      total: 0,
    },
  },
  jobs: {},
  indices: {},
  licenseStatus: {
    status: undefined,
    missing: true,
    loading: true,
  },
};

export default connect(
  ArchivePage,
  {
    archives: ArchiveStore as Store<ArchiveStoreState>,
    licenseStatus: ArchiveLicenseStore,
    systemJobs: SystemJobsStore as Store<{ jobs: Jobs }>,
    indices: IndicesStore as Store<{ indices: Indices }>,
  },
  ({ archives: { catalog }, systemJobs, indices, ...otherProps }) => ({
    catalog: catalog,
    jobs: systemJobs.jobs,
    indices: indices.indices,
    ...otherProps,
  }),
);
