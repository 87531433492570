import * as React from 'react';
import { useContext, useRef } from 'react';
import * as Immutable from 'immutable';

import { BackendMessage } from 'views/components/messagelist/Types';

import { loadPrevPage, loadNextPage } from './LogViewDataFetching';
import ListStateContext from './ListStateContext';
import ScrollPositionContext from './ScrollPositionContext';
import type { LogViewState } from './LogViewStateProvider';

import type { LogViewSearchTypeData, PageRefs, TableRef } from '../LogViewWidget';

export const orderMessages = (messages) => [...messages].reverse();

type InternalListState = LogViewState['listState'];

const _getVisiblePagesWithMessages = (visiblePagesIds: InternalListState['visiblePagesIds'], loadedPages: InternalListState['loadedPages']) => {
  let visiblePages = Immutable.OrderedSet<[number, Array<BackendMessage>]>();

  visiblePagesIds.sort((pageIdA, pageIdB) => pageIdB - pageIdA).forEach((pageId) => {
    visiblePages = visiblePages.add([pageId, loadedPages[pageId]]);
  });

  return visiblePages;
};

type Props = {
  children: React.ReactNode,
  pageRefs: PageRefs,
  queryId: string,
  searchTypeData: LogViewSearchTypeData,
  tableRef: TableRef,
  listState: InternalListState,
  updateLogViewState: (logViewState: Omit<LogViewState, 'status'>) => void,
}

const ListStateProvider = ({
  children,
  pageRefs,
  queryId,
  searchTypeData: {
    after,
    effectiveTimerange,
    id: searchTypeId,
    total,
  },
  listState,
  tableRef,
  updateLogViewState,
}: Props) => {
  const {
    finishedScrollPositionUpdateRef,
    setFinishedScrollPositionUpdate,
    setLastPosition: setLastScrollPosition,
  } = useContext(ScrollPositionContext);

  const pages = _getVisiblePagesWithMessages(listState.visiblePagesIds, listState.loadedPages);
  const loadedAllPrevMessages = listState.loadedMessagesCount === total || !after;
  const loadPrevPromiseRef = useRef(undefined);

  const updateListState = (
    newInternalListState: InternalListState,
    scrollPositionUpdate: LogViewState['scrollPositionUpdate'],
  ) => {
    setFinishedScrollPositionUpdate(false);
    setLastScrollPosition(tableRef.current.scrollTop);
    updateLogViewState({ listState: newInternalListState, scrollPositionUpdate });
  };

  const _loadPrevPage = () => loadPrevPage({
    effectiveTimerange,
    finishedScrollPositionUpdateRef,
    internalListState: listState,
    loadedAllPrevMessages,
    loadPrevPromiseRef,
    pageRefs,
    queryId,
    searchTypeId,
    updateListState,
  });

  const _loadNextPage = () => loadNextPage({
    loadedAllPrevMessages,
    internalListState: listState,
    pageRefs,
    updateListState,
    finishedScrollPositionUpdateRef,
  });

  const _cancelLoadPrevPage = () => {
    if (!!loadPrevPromiseRef.current && pages.size > 1) {
      loadPrevPromiseRef.current.cancel();
      // eslint-disable-next-line no-param-reassign
      loadPrevPromiseRef.current = undefined;
    }
  };

  const state = {
    actions: {
      cancelLoadPrevPage: _cancelLoadPrevPage,
      loadNextPage: _loadNextPage,
      loadPrevPage: _loadPrevPage,
    },
    bottomPageId: listState.visiblePagesIds.min(),
    loadedAllPrevMessages,
    pages,
  };

  return (
    <ListStateContext.Provider value={state}>
      {children}
    </ListStateContext.Provider>
  );
};

export default ListStateProvider;
