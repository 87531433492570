// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1_FXb5DtT_RNJ-IJA_g1M1 {\n    text-align: center;\n    width: 25px;\n}\n\n._1_FXb5DtT_RNJ-IJA_g1M1 > i {\n    font-size: 1.333em;\n    line-height: 0.75em;\n}\n\n.CfTMxn9B6BySbQtRJMiAE {\n    width: 300px;\n}", "",{"version":3,"sources":["webpack://./src/web/report/report-history-page/ReportHistory.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[":local(.status) {\n    text-align: center;\n    width: 25px;\n}\n\n:local(.status) > i {\n    font-size: 1.333em;\n    line-height: 0.75em;\n}\n\n:local(.date) {\n    width: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "_1_FXb5DtT_RNJ-IJA_g1M1",
	"date": "CfTMxn9B6BySbQtRJMiAE"
};
export default ___CSS_LOADER_EXPORT___;
