import React, { useContext } from 'react';
import { ControlledTableList, Icon } from 'components/common';
import styled, { css } from 'styled-components';

import { Pack } from './types';
import { PackContext } from './context/PackContext';

type Props = {
  packItem: Pack,
  onChange: (string, boolean) => void,
}

const FlexWrap = styled.div(({ theme }) => css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: ${theme.breakpoints.min.md}) {
    flex-wrap: nowrap;
  }
`);

const FlexLarge = styled.div(({ theme }) => css`
  flex-basis: 100%;
  
  p {
    max-width: 30rem;
  }

  @media (min-width: ${theme.breakpoints.min.md}) {
    flex-basis: 50%;
  }
`);

const H4 = styled.h4(({ theme }) => css`
  margin-bottom: ${theme.spacings.md};
  display: flex;
  align-items: center;
`);

const EnabledMark = styled.span(({ theme }) => css`
  color: ${theme.colors.variant.success};
  font-weight: bold;
  font-size: ${theme.fonts.size.tiny};
  margin-left: ${theme.spacings.sm};
`);

const Checkbox = styled.input(({ theme }) => css`
  &[type="checkbox"] {
    margin-right: ${theme.spacings.sm};
  }
`);

const StyledTableListItem = styled(ControlledTableList.Item)<{$enabled?: boolean}>(({ $enabled, theme }) => css`
  background-color: ${$enabled ? theme.colors.variant.lightest.success : 'inherit'};
`);

const IlluminatePackItem = ({ packItem, onChange }: Props) => {
  const { selectedPackIds } = useContext(PackContext);
  const isChecked = selectedPackIds.findIndex((pack) => pack === packItem.pack_id) >= 0;

  return (
    <StyledTableListItem $enabled={packItem.enabled}>
      <FlexWrap>
        <FlexLarge>
          <H4>
            <span>
              <Checkbox type="checkbox"
                        name="pack-item-selection"
                        checked={isChecked}
                        onChange={(event) => onChange(packItem.pack_id, event.currentTarget.checked)} />
              <span>{packItem.title}</span>
            </span>

            {packItem.enabled && (<EnabledMark><Icon name="check" /> Enabled</EnabledMark>)}
          </H4>
          <p>{packItem.description}</p>
        </FlexLarge>

      </FlexWrap>
    </StyledTableListItem>
  );
};

export default IlluminatePackItem;
