import { filterCloudMenuItems } from 'util/conditional/filterMenuItems';

export default {
  navigation: [
    {
      description: 'Enterprise',
      children: filterCloudMenuItems(
        [
          { path: '/system/enterprise', description: 'Overview', permissions: 'archive:read' },
          { path: '/system/archives', description: 'Archives', permissions: 'archive:read' },
          { path: '/system/auditlog', description: 'Audit Log', permissions: 'auditlog_status:read' },
          { path: '/system/licenses', description: 'Licenses', permissions: 'inputs:create' },
          { path: '/reports', description: 'Reports', permissions: 'report:update' },
          { path: '/customization', description: 'Customization', permissions: 'clusterconfig:read' },
          { path: '/system/forwarders', description: 'Forwarders', permissions: 'forwarders:read' },
          { path: '/illuminate', description: 'Illuminate', permissions: '*' },
        ],
        ['/system/licenses', '/system/forwarders', '/illuminate'],
      ),
    },
  ],
};
