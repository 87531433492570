import * as React from 'react';
import { singleton } from 'views/logic/singleton';

export type LicenseCheckContextType = {
  security: boolean,
  illuminate: boolean
}

const LicenseCheckContext = React.createContext<LicenseCheckContextType | undefined>(undefined);

export default singleton('contexts.LicenseCheckContext', () => LicenseCheckContext);
