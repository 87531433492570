import * as React from 'react';

import { singleton } from 'views/logic/singleton';

type ScrollPosition = {
  finishedScrollPositionUpdateRef: { current: boolean },
  lastPositionRef: { current: number },
  setFinishedScrollPositionUpdate: (finished: boolean) => void
  setLastPosition: (position: number) => void,
}

const ScrollPositionContext = React.createContext<ScrollPosition | undefined>(undefined);

export default singleton('contexts.enterprise.LogViewScrollPositionContext', () => ScrollPositionContext);
