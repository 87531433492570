import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';

import { Table, Button, DropdownButton, OverlayTrigger, Popover } from 'components/graylog';
import { Icon, PaginatedList, SearchForm } from 'components/common';
import ArchiveCatalogTableEntry from 'archive/components/ArchiveCatalogTableEntry';
import ArchiveCatalogExportModal from 'archive/components/ArchiveCatalogExportModal';
import ArchiveActions from 'archive/ArchiveActions';
import {
  ArchivesContextPropType,
  ArchivePropType,
  BackendContextPropType,
  PaginationPropType,
} from 'archive/propTypes';

import { StyledCatalogWrapperComponent } from './StyledCatalogComponents';

import type { Archive, BackendContext, ArchivesContext, Pagination } from '../types';

const StyledDropDownbutton = styled(DropdownButton)`
  margin-left: 5px;
`;

const StyledPopover = styled(Popover)`
  max-width: 500px;
`;

type Props = {
  archives: Array<Archive>,
  archivesContext: {
    [key: string]: ArchivesContext,
  },
  backendsContext: {
    [key: string]: BackendContext,
  },
  pagination: Pagination,
};

const ArchiveCatalog = ({ archives, archivesContext, backendsContext, pagination }: Props) => {
  const PAGE_SIZES = [20, 50, 100, 200, 500];
  const DEFAULT_PAGE_SIZE = 20;

  const _onPageChange = (newPage: number, pageSize: number) => {
    const { query } = pagination;

    if (archives) {
      ArchiveActions.searchPaginated(newPage, pageSize, query);
    }
  };

  const _onSearch = (query: string, resetLoadingStateCb) => {
    const { page, per_page: perPage } = pagination;

    ArchiveActions
      .searchPaginated(page, perPage, query)
      .finally(resetLoadingStateCb);
  };

  const _onReset = () => {
    const { page, per_page: perPage } = pagination;
    ArchiveActions.searchPaginated(page, perPage);
  };

  const { total, query } = pagination;
  const archivesRows = archives.map((archive) => {
    return (
      <ArchiveCatalogTableEntry key={`archive-entry-${archive.id}`}
                                archive={archive}
                                context={archivesContext[archive.backend_id][archive.archive_id]}
                                backendContext={backendsContext[archive.backend_id]} />
    );
  });

  const popover = (
    <StyledPopover id="search-query-help" className="popover-wide" title="Search Syntax Help">
      <p><strong>Available search fields</strong></p>
      <Table condensed>
        <thead>
          <tr>
            <th>Field</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>index</td>
            <td>Index name of an archive</td>
          </tr>
          <tr>
            <td>id</td>
            <td>Archive ID</td>
          </tr>
          <tr>
            <td>nodes</td>
            <td>Server node names for archived messages</td>
          </tr>
          <tr>
            <td>streams</td>
            <td>Stream names for archived messages</td>
          </tr>
          <tr>
            <td>sources</td>
            <td>Source names in archived messages</td>
          </tr>
          <tr>
            <td>created</td>
            <td>Archive creation date</td>
          </tr>
          <tr>
            <td>from</td>
            <td>Minimum message timestamp in archive</td>
          </tr>
          <tr>
            <td>to</td>
            <td>Maximum message timestamp in archive</td>
          </tr>
        </tbody>
      </Table>
      <p><strong>Examples</strong></p>
      <p>
        Find archives which contain messages in a date range:<br />
        <code>{'from:>=2017-03-23 to:<=2017-04-01'}</code><br />
        <code>{'from:">=2016-02-18 18:10:37.055" to:"<=2016-02-18 18:13:16.368"'}</code>
      </p>
      <p>
        Find archives which contain messages from the <var>errors</var> stream:<br />
        <code>streams:errors</code>
      </p>
      <p>
        Find archives which contain messages from the <var>errors</var> and <var>warnings</var> streams:<br />
        <code>streams:errors streams:warnings</code><br />
        <code>streams:errors,warnings</code>
      </p>
      <p>
        Find archives which contain messages from the <var>foo</var> index and the <var>errors</var> stream:<br />
        <code>index:foo streams:errors</code><br />
      </p>
    </StyledPopover>
  );

  return (
    <StyledCatalogWrapperComponent>
      <h2>
        Archive Catalog
        <span>&nbsp;<small>{total} total</small></span>
      </h2>
      <div>
        <PaginatedList totalItems={total}
                       pageSize={DEFAULT_PAGE_SIZE}
                       pageSizes={PAGE_SIZES}
                       onChange={_onPageChange}>
          <SearchForm onSearch={_onSearch} searchBsStyle="success" onReset={_onReset} queryWidth={500} useLoadingState>
            <StyledDropDownbutton id="export-results-dropdown" title="Export Results">
              <ArchiveCatalogExportModal query={query || ''} />
            </StyledDropDownbutton>
            <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
              <Button bsStyle="link" className="archive-search-help-button">
                <Icon name="question-circle" fixedWidth />
              </Button>
            </OverlayTrigger>
          </SearchForm>
          <Table condensed hover>
            <thead>
              <tr>
                <th>Index</th>
                <th>Backend</th>
                <th>Created</th>
                <th>Range</th>
                <th>Content</th>
                <th>Streams</th>
                <th className="restored">Restored</th>
              </tr>
            </thead>
            {archivesRows}
          </Table>
        </PaginatedList>
      </div>
    </StyledCatalogWrapperComponent>
  );
};

ArchiveCatalog.propTypes = {
  archives: PropTypes.arrayOf(ArchivePropType).isRequired,
  archivesContext: PropTypes.objectOf(ArchivesContextPropType).isRequired,
  backendsContext: PropTypes.objectOf(BackendContextPropType).isRequired,
  pagination: PaginationPropType.isRequired,
};

export default ArchiveCatalog;
