// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Ve9GaHKY9UrLDffmdE6Ey img {\n    max-width: 150px;\n    max-height: 150px;\n}", "",{"version":3,"sources":["webpack://./src/web/report/commonStyles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[":local(.logo) img {\n    max-width: 150px;\n    max-height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_1Ve9GaHKY9UrLDffmdE6Ey"
};
export default ___CSS_LOADER_EXPORT___;
