import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Immutable from 'immutable';

import connect from 'stores/connect';
import { SearchMetadataStore } from 'views/stores/SearchMetadataStore';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import { SearchParameterActions } from 'enterprise/parameters/stores/SearchParameterStore';

import ParameterBar from './ParameterBar';

const CenteringContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenteredBox = styled.div`
  position: absolute;
  background: white;
  z-index: 1;
  left: 0;
  right: 0;
  width: max-content;
  margin: 0 auto;
`;

type Props = {
  undeclaredParameterNames: Immutable.Set<string>,
  retry: () => void,
};

const UndeclaredParameterAlert = connect(({ undeclaredParameterNames, retry }: Props) => {
  if (undeclaredParameterNames.size === 0) {
    retry();

    return null;
  }

  return (
    <QueryEditModeContext.Provider value="widget">
      <CenteringContainer>
        <CenteredBox>
          <ParameterBar undeclaredParameters={undeclaredParameterNames}
                        onParameterSave={SearchParameterActions.declare} />
        </CenteredBox>
      </CenteringContainer>
    </QueryEditModeContext.Provider>
  );
},
{ searchMetadata: SearchMetadataStore },
({ searchMetadata }) => ({ undeclaredParameterNames: searchMetadata.undeclared }));

const WidgetEditModeParameterBar = ({ undeclaredParameterNames, override }) => {
  useEffect(() => {
    if (undeclaredParameterNames.size > 0) {
      override(UndeclaredParameterAlert);
    }
  }, [undeclaredParameterNames]);

  return null;
};

export default connect(
  WidgetEditModeParameterBar,
  { searchMetadata: SearchMetadataStore },
  ({ searchMetadata }) => ({ undeclaredParameterNames: searchMetadata.undeclared }),
);
