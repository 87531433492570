// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3zfl54GHmLgm9YDJzdZQ1E {\n    margin-left: 3rem;\n    margin-right: 3rem;\n    margin-top: 2rem;\n}\n", "",{"version":3,"sources":["webpack://./src/web/enterprise/parameters/components/views/SelectViewWithParameter.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[":local(.indentingParameters) {\n    margin-left: 3rem;\n    margin-right: 3rem;\n    margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indentingParameters": "_3zfl54GHmLgm9YDJzdZQ1E"
};
export default ___CSS_LOADER_EXPORT___;
