import * as React from 'react';

import { LinkContainer } from 'components/graylog/router';
import Routes from 'routing/Routes';
import { Button } from 'components/graylog';
import { PageHeader, DocumentTitle } from 'components/common';
import UserOverviewLinks from 'components/users/navigation/UserOverviewLinks';
import DocumentationLink from 'components/support/DocumentationLink';
import DocsHelper from 'util/DocsHelper';

import LicenseCheck from '../../license/LicenseCheck';
import TeamsOverview from '../components/teams-overview/TeamsOverview';

const TeamsOverviewPage = () => (
  <DocumentTitle title="Teams Overview">
    <PageHeader title="Teams Overview"
                subactions={(
                  <LicenseCheck featureName="teams" displayLicenseWarning={false}>
                    {({ licenseIsValid }) => (
                      <LinkContainer to={Routes.pluginRoute('SYSTEM_TEAMS_NEW')}>
                        <Button bsStyle="success" disabled={!licenseIsValid}>
                          Create Team
                        </Button>
                      </LinkContainer>
                    )}
                  </LicenseCheck>
                )}>
      <span>
        Overview of Graylog&apos;s teams. Teams allow granting permissions and capabilities to multiple users.
        The team details page provides an overview of all entities which are being shared with a team.
        Teams can be managed manually or synced from external resources like LDAP.
      </span>
      <span>
        Learn more in the{' '}
        <DocumentationLink page={DocsHelper.PAGES.WELCOME}
                           text="documentation" />
      </span>
      <UserOverviewLinks />
    </PageHeader>

    <TeamsOverview />
  </DocumentTitle>
);

export default TeamsOverviewPage;
