import React, { useContext } from 'react';

import { LinkContainer } from 'components/graylog/router';
import Routes from 'routing/Routes';
import { Button, Col, Row } from 'components/graylog';
import { EmptyEntity } from 'components/common';
import CurrentUserContext from 'contexts/CurrentUserContext';
import { isPermitted } from 'util/PermissionsMixin';

const EmptyForwarderListComponent = () => {
  const currentUser = useContext(CurrentUserContext);

  return (
    <Row className="content">
      <Col md={12}>
        <Row>
          <Col md={6} mdOffset={3} lg={4} lgOffset={4}>
            <EmptyEntity>
              {isPermitted(currentUser?.permissions, 'forwarders:create')
                ? (
                  <>
                    <p>
                      Forwarders allow you to send log messages from your local network to Graylog. Click on
                      the button below to get started and configure your first Forwarder.
                    </p>
                    <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS_NEW')}>
                      <Button bsStyle="success">Get Started!</Button>
                    </LinkContainer>
                  </>
                ) : (
                  <p>Forwarders allow you to send log messages from your local network to Graylog.</p>
                )}
            </EmptyEntity>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmptyForwarderListComponent;
