import React from 'react';
import PropTypes from 'prop-types';

import { Alert, Col, ButtonToolbar, Button } from 'components/graylog';
import { Icon } from 'components/common';

type Props = {
  type: string,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
  goToPrevious: () => void,
}

const TeamSyncNotAvailable = ({ type, goToPrevious, handleSubmit }: Props) => {
  const handleSave = () => {
    goToPrevious();
    handleSubmit();
  };

  return (
    <Col md={7}>
      <Alert bsStyle="info">
        <b><Icon name="info-circle" />{' '}Team Synchronization</b><br />
        {`${type.toUpperCase()} authentication service does not support team synchronization. `}
        Team sync is only supported in Okta, LDAP and Active Directory authentication services.
      </Alert>
      <ButtonToolbar className="pull-right">
        <Button onClick={handleSave}>
          Finish & Save Service
        </Button>
        <Button bsStyle="primary"
                onClick={goToPrevious}
                type="button">
          Previous: Server Configuration
        </Button>
      </ButtonToolbar>
    </Col>
  );
};

TeamSyncNotAvailable.propTypes = {
  type: PropTypes.string.isRequired,
  goToPrevious: PropTypes.func.isRequired,
};

export default TeamSyncNotAvailable;
