import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import styled, { css } from 'styled-components';

import { ReactGridContainer } from 'components/common';

import ReportingWidget from './ReportingWidget';
import ReportingWidgetContainer from './ReportingWidgetContainer';

const ROW_HEIGHT = 200 + 42; // Minimum widget height + padding + border
const MAX_HEIGHT = 2; // Do not use more than 2 rows, helping to move large widgets around
const StyledReportWidgetContainerWrapper = styled.div(({ theme }) => css` 
  overflow: hidden;
  padding: 20px 40px;
  
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
  }
  
  @media screen {
    border: 1px solid ${theme.colors.gray['80']};
    cursor: move;
  }
`);

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

class ReportPreview extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    onPositionsChange: PropTypes.func,
    locked: PropTypes.bool,
    interactive: PropTypes.bool,
  };

  static defaultProps = {
    onPositionsChange: () => {},
    locked: false,
    interactive: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      widgetDimensions: {},
    };
  }

  _calculateDimension = (value) => {
    // Return dimension if integer, but round up in other case. E.g. 0.8 columns should be 1 column, but 1 should stay 1.
    return lodash.isInteger(value) ? value : lodash.toSafeInteger(value) + 1;
  };

  _pixelsToCoordinates = (widgetDimensions) => {
    return {
      height: Math.min(this._calculateDimension(widgetDimensions.height / ROW_HEIGHT), MAX_HEIGHT),
      width: 1, // There is only one column in the grid
    };
  };

  _handleDimensionsChange = (widgetId) => {
    return (dimensions) => {
      const nextWidgetDimensions = {};
      nextWidgetDimensions[widgetId] = this._pixelsToCoordinates(dimensions);
      this.setState((prevState) => ({ widgetDimensions: { ...prevState.widgetDimensions, ...nextWidgetDimensions } }));
    };
  };

  _renderWidgets = (widgets) => {
    const { interactive, report: { parameterValues } } = this.props;

    return widgets.map((widget) => {
      return (
        <StyledReportWidgetContainerWrapper key={widget.dashboard_widget_id}>
          <ReportingWidgetContainer onDimensionsChange={this._handleDimensionsChange(widget.dashboard_widget_id)}>
            <ReportingWidget dashboardId={widget.dashboard_id}
                             widget={widget}
                             width={0} // ReportWidgetContainer will take care of setting that
                             height={0} // ReportWidgetContainer will take care of setting that
                             interactive={interactive}
                             parameterValues={parameterValues}
                             showCaption={false} />
          </ReportingWidgetContainer>
        </StyledReportWidgetContainerWrapper>
      );
    });
  };

  // Format layout from [{ dashboard_widget_id: 'foo', row: 1, col 1 }] to { 'foo': { row: 1, col: 1 } }, and merge
  // that with the widget sizes.
  // This method will generate an object of positions that `ReactGridContainer` can understand.
  _calculateLayout = (positions, widgetDimensions) => {
    const persistedPositions = positions || [];
    const formattedPositions = {};

    persistedPositions.forEach((position) => {
      formattedPositions[position.dashboard_widget_id] = {
        row: position.row,
        col: position.col,
      };
    });

    return lodash.merge({}, formattedPositions, widgetDimensions);
  };

  render() {
    const { report, onPositionsChange, locked, interactive } = this.props;
    const { widgetDimensions } = this.state;

    const layout = this._calculateLayout(report.positions, widgetDimensions);

    return (
      <StyledDiv>
        <ReactGridContainer positions={layout}
                            onPositionsChange={onPositionsChange}
                            rowHeight={ROW_HEIGHT}
                            isResizable={false}
                            animate={interactive}
                            locked={locked}
                            columns={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
          {this._renderWidgets(report.widgets)}
        </ReactGridContainer>
      </StyledDiv>
    );
  }
}

export default ReportPreview;
