// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kh5Pwxd-EjJV49JPLKPYo {\n    margin-bottom: 15px;\n    line-height: 1.3em;\n}\n\n@media print {\n    .kh5Pwxd-EjJV49JPLKPYo {\n        margin-top: 15px;\n        page-break-after: avoid;\n    }\n}", "",{"version":3,"sources":["webpack://./src/web/report/common/Heading.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,gBAAgB;QAChB,uBAAuB;IAC3B;AACJ","sourcesContent":[":local(.heading) {\n    margin-bottom: 15px;\n    line-height: 1.3em;\n}\n\n@media print {\n    :local(.heading) {\n        margin-top: 15px;\n        page-break-after: avoid;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "kh5Pwxd-EjJV49JPLKPYo"
};
export default ___CSS_LOADER_EXPORT___;
