import AppConfig from 'util/AppConfig';

import IlluminatePage from './IlluminatePage';

const isCloud = AppConfig.isCloud();

export default {
  routes: isCloud ? [] : [
    { path: '/illuminate', component: IlluminatePage, permissions: '*' },
    { path: '/illuminate/install', component: IlluminatePage, permissions: '*' },
  ],
};
