import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { PluginStore } from 'graylog-web-plugin/plugin';
import { get } from 'lodash';

import ColorMapper from 'views/components/visualizations/ColorMapper';
import WidgetVisualizationNotFound from 'components/widgets/WidgetVisualizationNotFound';
import Icon from 'components/common/Icon';
import InteractiveContext from 'views/components/contexts/InteractiveContext';
import searchTypeDefinition from 'views/logic/SearchType';
import FieldTypeMapping from 'views/logic/fieldtypes/FieldTypeMapping';
import RenderCompletionCallback from 'views/components/widgets/RenderCompletionCallback';
import ChartColorContext from 'views/components/visualizations/ChartColorContext';

import Caption from './Caption';
import Heading from './Heading';
import WidgetDescription, { FallbackWidgetDescription } from './WidgetDescription';
import ErrorBoundary from './ErrorBoundary';
import style from './ReportingWidget.css';

import { MESSAGE_LIST_WIDGET_LIMIT } from '../Constants';
import { AvailableWidgetsStore } from '../AvailableWidgetsStore';

const _searchTypePlugin = (type) => {
  const typeDefinition = searchTypeDefinition(type);

  return typeDefinition && typeDefinition.handler
    ? searchTypeDefinition(type).handler
    : {
      convert: (result) => {
        // eslint-disable-next-line no-console
        console.error(`No search type handler for type '${type}' result:`, result);

        return result;
      },
    };
};

export const FallbackReportingWidget = ({ widget, error }) => (
  <div className={style.reportVisualization}>
    <div className={style.heading}>
      <Heading title={get(widget, 'description', 'Unknown widget')} />
    </div>
    <div className={style.visualizationContainer}>
      There was an error loading this widget. Please ensure the widget supports Reporting and it loads on its
      Dashboard.<br />
      <code>{error.toString()}</code>
    </div>
  </div>
);

FallbackReportingWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  error: PropTypes.instanceOf(Error).isRequired,
};

class ReportingWidget extends React.Component {
  static propTypes = {
    dashboardId: PropTypes.string.isRequired,
    widget: PropTypes.object.isRequired,
    showHeading: PropTypes.bool,
    showCaption: PropTypes.bool,
    showHandle: PropTypes.bool,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    interactive: PropTypes.bool,
    limitHeight: PropTypes.bool,
    parameterValues: PropTypes.object,
  };

  static defaultProps = {
    showHeading: true,
    showCaption: true,
    showHandle: true,
    interactive: true,
    limitHeight: false,
    parameterValues: {},
  };

  constructor(props) {
    super(props);
    this.state = this._cleanState();
  }

  componentDidMount() {
    this._loadValue();
  }

  componentWillReceiveProps(nextProps) {
    const { widget } = this.props;

    if (this.getWidgetId(nextProps.widget) !== this.getWidgetId(widget)) {
      this.setState(this._cleanState());
    }
  }

  componentDidUpdate(prevProps) {
    const { widget } = this.props;

    if (this.getWidgetId(prevProps.widget) !== this.getWidgetId(widget)) {
      this._loadValue();
    }
  }

  /*
   * This allows us to handle reporting widgets (ID in `dashboard_widget_id`) and dashboard widgets (ID in `id`)
   * interchangeably.
   */
  getWidgetId = (widget) => {
    return widget.dashboard_widget_id || widget.id;
  };

  _cleanState = () => {
    return {
      result: undefined,
      calculatedAt: undefined,
      error: false,
      errorMessage: undefined,
    };
  };

  _errorHandler = (response) => {
    const { result } = this.state;
    const error = response.message;
    const newResult = result === undefined ? 'N/A' : result;

    this.setState({
      result: newResult,
      error: true,
      errorMessage: `Error loading widget value: ${error}`,
    }, () => {
      if (this.context) {
        this.context();
      }
    });
  };

  _loadValue = () => {
    const { dashboardId, widget, parameterValues } = this.props;
    const globalOverride = widget.type === 'messages'
      ? { limit: MESSAGE_LIST_WIDGET_LIMIT, offset: 0 }
      : {};

    AvailableWidgetsStore.value(dashboardId, this.getWidgetId(widget), parameterValues, globalOverride)
      .then(
        (value) => {
          if (value.errors && value.errors.length > 0) {
            const message = value.errors.map((error) => error.description).join(' / ');
            this._errorHandler({ message });

            return;
          }

          const { calculatedAt } = this.state;

          // Avoid updating state if the result didn't change
          if (value.calculated_at === calculatedAt) {
            return;
          }

          const mappedResults = value.result.map((searchType) => _searchTypePlugin(searchType.type).convert(searchType));
          const widgetPlugin = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase()) || {};
          const { searchResultTransformer = (x) => x } = widgetPlugin;
          const newState = {
            result: searchResultTransformer(mappedResults),
            calculatedAt: value.calculated_at,
            error: false,
            errorMessage: undefined,
            types: Immutable.List(value.field_types.map((type) => FieldTypeMapping.fromJSON(type))),
          };

          if (value.computation_time_range) {
            newState.computationTimeRange = value.computation_time_range;
          }

          this.setState(newState);
        },
        this._errorHandler,
      )
      .catch(this._errorHandler);
  };

  _getVisualization = (widget) => {
    if (widget.type === '') {
      return null;
    }

    const { result, error, errorMessage, types } = this.state;

    if (result === undefined) {
      return (
        <div className={style.loading}>
          <Icon spin name="spinner" />
        </div>
      );
    }

    if (result === 'N/A') {
      return (
        <>
          <div className={style.notAvailable}>{result}</div>
          {error && errorMessage}
        </>
      );
    }

    const { height, width, interactive, limitHeight } = this.props;

    const widgetPlugin = PluginStore.exports('enterpriseWidgets').find((w) => w.type.toUpperCase() === widget.type.toUpperCase());

    if (!widgetPlugin) {
      return <WidgetVisualizationNotFound widgetClassName={widget.type} />;
    }

    const headingHeight = this._heading ? this._heading.scrollHeight : 0;
    const captionHeight = this._caption ? this._caption.scrollHeight : 0;
    const marginHeight = 10;
    const plotLegend = 55;
    const Visualization = widgetPlugin.visualizationComponent;
    const maxHeight = limitHeight || widgetPlugin.needsControlledHeight(widget) ? height - headingHeight - captionHeight - marginHeight - plotLegend : '100%';
    const maxWidth = width;

    return (
      <InteractiveContext.Provider value={interactive}>
        <div style={{ maxHeight, maxWidth, height: maxHeight, width: maxWidth, margin: 'auto' }}>
          <Visualization id={this.getWidgetId(widget)}
                         config={widget.config}
                         editing={false}
                         data={result}
                         fields={types}
                         height={height - headingHeight - captionHeight - marginHeight}
                         width={width}
                         locked />
        </div>
      </InteractiveContext.Provider>
    );
  };

  static contextType = RenderCompletionCallback;

  render() {
    const { widget, showCaption, showHeading, showHandle } = this.props;
    const { calculatedAt } = this.state;

    const Description = (
      <ErrorBoundary FallbackComponent={FallbackWidgetDescription}>
        <WidgetDescription widget={widget} calculatedAt={calculatedAt} />
      </ErrorBoundary>
    );

    const chartColors = widget?.config?.formattingSettings?.chartColors ?? {};
    const colorMap = ColorMapper.create(Immutable.Map(chartColors));

    return (
      <ChartColorContext.Provider value={{ colors: colorMap }}>
        <div className={style.reportVisualization}>
          {showHandle && <div className={style.draggableHandle}><Icon name="sort" /></div>}
          {showHeading && (
            <div className={style.heading} ref={(c) => { this._heading = c; }}>
              <Heading title={widget.description} />
            </div>
          )}
          <div className={style.visualizationContainer}>
            {this._getVisualization(widget)}
          </div>
          {showCaption && (
            <div className={style.caption} ref={(c) => { this._caption = c; }}>
              <Caption text={Description} />
            </div>
          )}
        </div>
      </ChartColorContext.Provider>
    );
  }
}

export default ReportingWidget;
