import React, { useContext, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Select, Spinner } from 'components/common';
import { ControlLabel, MenuItem, SplitButton } from 'components/graylog';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { Link } from 'components/graylog/router';

import LicenseCheckContext from 'license/LicenseCheckContext';

import { IlluminateActions } from './stores/IlluminateStore';
import { BundleContext } from './context/BundleContext';

const BundleVersion = styled.div`
  display: flex;
`;

const StyledSelect = styled(Select)(({ theme }) => css`
  flex: 1;
  margin-right: ${theme.spacings.xs};
  width: 160px;
`);

const InstallCTA = styled.p(({ theme }) => css`
  && {
    text-align: right;
    margin: 0;
    font-size: ${theme.fonts.size.small};
    padding-top: ${theme.spacings.xs};
  }
`);

const IlluminateBundleVersion = () => {
  const { bundles, bundleVersion, setBundleVersion, setBundleLoading } = useContext(BundleContext);
  const { illuminate: illuminateLicenseIsValid } = useContext(LicenseCheckContext);
  const [disabledBundleConfirm, setDisabledBundleConfirm] = useState<boolean>(true);
  const [nextVersion, setNextVersion] = useState<string>(bundleVersion);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const bundleOptions = useMemo(() => bundles.map((bundle) => ({
    label: bundle.title,
    value: bundle.version,
  })), [bundles]);

  const toggleShowConfirmDialog = () => {
    setShowConfirmDialog(!showConfirmDialog);
  };

  const toggleShowDeleteConfirmDialog = () => {
    setShowDeleteConfirmDialog(!showDeleteConfirmDialog);
  };

  const confirmBundleVersion = () => {
    setIsLoading(true);

    IlluminateActions.updateBundle(nextVersion, { enabled: true }).then(() => {
      toggleShowConfirmDialog();
      setDisabledBundleConfirm(true);
      setBundleVersion(nextVersion);
    }).finally(() => setIsLoading(false));
  };

  const confirmDeleteBundleVersion = () => {
    setIsLoading(true);

    IlluminateActions.deleteBundle(nextVersion).then(() => {
      toggleShowDeleteConfirmDialog();
      setBundleLoading(true);
    }).finally(() => setIsLoading(false));
  };

  if (!illuminateLicenseIsValid) {
    return null;
  }

  return (
    <div>
      <ControlLabel htmlFor="illuminate-bundles">Illuminate Bundle Version</ControlLabel>
      <BundleVersion>
        <StyledSelect options={bundleOptions}
                      value={bundleVersion}
                      name="illuminate-bundles"
                      inputId="illuminate-bundles"
                      size="small"
                      clearable={false}
                      onChange={(newVersion: string) => {
                        setNextVersion(newVersion);
                        setDisabledBundleConfirm(newVersion === bundleVersion);
                      }} />
        <SplitButton id="bundle-dropdown"
                     onClick={toggleShowConfirmDialog}
                     bsStyle="success"
                     bsSize="small"
                     title="Activate"
                     disabled={disabledBundleConfirm}>
          <MenuItem eventKey="1" onClick={toggleShowDeleteConfirmDialog}>Delete</MenuItem>
        </SplitButton>
      </BundleVersion>

      <InstallCTA>
        <Link to="/illuminate/install">Install Another Bundle</Link>
      </InstallCTA>

      <ConfirmDialog show={showConfirmDialog}
                     btnConfirmText={isLoading ? <Spinner text="Activating..." delay={0} /> : 'Confirm'}
                     btnConfirmDisabled={isLoading}
                     onCancel={toggleShowConfirmDialog}
                     onConfirm={confirmBundleVersion}
                     title="Are you sure?">
        <>
          <p>You are about to change Illuminate to version <strong>{nextVersion}</strong>.</p>
          <p>This will pause message processing on all Graylog nodes while the change is applied.</p>
          <p>All previously enabled packs will remain active in the new version.</p>
          <p>Do you wish to continue?</p>
        </>

      </ConfirmDialog>

      <ConfirmDialog show={showDeleteConfirmDialog}
                     btnConfirmText={isLoading ? <Spinner text="Deleting..." delay={0} /> : 'Confirm'}
                     btnConfirmDisabled={isLoading}
                     onConfirm={confirmDeleteBundleVersion}
                     onCancel={toggleShowDeleteConfirmDialog}
                     title="Are you sure?">
        <>
          <p>You are about to delete Illuminate version <strong>{nextVersion}</strong>.</p>
          <p>This action cannot be undone.</p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>
    </div>
  );
};

export default IlluminateBundleVersion;