import React from 'react';
import { LinkContainer } from 'components/graylog/router';
import { DocumentTitle, PageHeader } from 'components/common';
import withParams from 'routing/withParams';
import { Button, ButtonToolbar, Col, Row } from 'components/graylog';
import Routes from 'routing/Routes';
import DocumentationLink from 'components/support/DocumentationLink';

import ForwarderDocs from 'forwarder/util/ForwarderDocs';
import withIsPermitted from 'forwarder/util/withIsPermitted';

import ForwarderWizard from './ForwarderWizard';

type ForwarderWizardPageProps = {
  params: {
    forwarderId?: string,
  },
};

const REQUIRED_PERMISSIONS = [
  'forwarders:create', 'forwarders:edit', 'forwarders:read',
  'inputprofiles:create', 'inputprofiles:edit', 'inputprofiles:read',
  'forwarderinputs:create', 'forwarderinputs:edit', 'forwarderinputs:read',
];

const ForwarderWizardPage = ({ params }: ForwarderWizardPageProps) => {
  const pageHeader = params && params.forwarderId ? 'Configure Forwarder' : 'New Forwarder';

  return (
    <DocumentTitle title={pageHeader}>
      <>
        <PageHeader title={pageHeader}>
          <>
            Set up a Graylog Forwarder to start sending data into Graylog.
          </>

          <>
            Learn more about Graylog Forwarders in the{' '}
            <DocumentationLink text="documentation" page={ForwarderDocs.FORWARDER} />.
          </>

          <ButtonToolbar>
            <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
              <Button bsStyle="info">Exit Forwarder Setup</Button>
            </LinkContainer>
          </ButtonToolbar>
        </PageHeader>

        <Row className="content">
          <Col md={12}>
            <ForwarderWizard forwarderId={params && params.forwarderId} />
          </Col>
        </Row>
      </>
    </DocumentTitle>
  );
};

export default withParams(withIsPermitted(ForwarderWizardPage, REQUIRED_PERMISSIONS));
