import React, { useMemo, useEffect } from 'react';

import { useStore } from 'stores/connect';
import { Spinner } from 'components/common';
import { Label } from 'components/graylog';

import ForwarderInputStatesStore, { ForwarderInputStatesActions } from '../stores/ForwarderInputStatesStore';
import { Forwarder, InputState, Input, ForwarderInputState } from '../Types';

type ForwarderInputStateBadgeProps = {
  forwarder?: Forwarder;
  input: Input;
};

const stateToLabelStyle = (state?: InputState): string => {
  switch (state) {
    case 'RUNNING':
      return 'success';
    case 'FAILED':
    case 'INVALID_CONFIGURATION':
      return 'danger';
    case 'STARTING':
    case 'CREATED':
    case 'INITIALIZED':
    case 'STOPPED':
    case 'TERMINATED':
    case 'STOPPING':
      return 'info';
    default:
      return 'warning';
  }
};

const stateToLabelText = (state?: InputState): string => {
  switch (state) {
    case 'RUNNING':
      return 'Running';
    case 'FAILED':
    case 'INVALID_CONFIGURATION':
      return 'Failed';
    case 'STARTING':
    case 'CREATED':
    case 'INITIALIZED':
      return 'Starting';
    case 'STOPPED':
    case 'TERMINATED':
      return 'Not running';
    case 'STOPPING':
      return 'Stopping';
    default:
      return 'Unknown';
  }
};

const ForwarderInputStateBadge = ({ forwarder, input }: ForwarderInputStateBadgeProps) => {
  const { forwarderInputStates: states } = useStore(ForwarderInputStatesStore);

  useEffect(() => {
    ForwarderInputStatesActions.register();

    return () => ForwarderInputStatesActions.unregister();
  }, []);

  const inputState = useMemo((): InputState | undefined => {
    if (!forwarder) {
      return undefined;
    }

    const statesByInput = (states || [])
      .filter((state: ForwarderInputState) => state.forwarder_id === forwarder.id)
      .filter((state: ForwarderInputState) => state.input_id === input.id);

    return statesByInput[0]?.state;
  }, [input, forwarder, states]);

  const hasResults = states !== null;

  if (!hasResults) {
    return <Spinner />;
  }

  return (
    <Label bsStyle={stateToLabelStyle(inputState)}>{stateToLabelText(inputState)}</Label>
  );
};

ForwarderInputStateBadge.defaultProps = {
  forwarder: undefined,
};

export default ForwarderInputStateBadge;
