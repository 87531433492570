import Reflux from 'reflux';
import { Notification, Notifications } from 'theme/types';

import { singletonActions } from 'views/logic/singleton';
import { RefluxActions } from 'stores/StoreTypes';

export type ActionsType = {
  updateTheme: (theme: {}) => Promise<{}>;
  loadTheme: () => Promise<unknown>;
  createPublicNotification: (notification: Notification) => Promise<Notification>;
  updatePublicNotification: (id: string, notification: Notification) => Promise<Notification>;
  loadAllPublicNotifications: () => Promise<Notifications>;
  loadPublicNotification: (id: string) => Promise<Notification>;
  deletePublicNotification: (id: string) => Promise<0 | 1>;
  getLicenseStatus: () => Promise<any>;
};

const CustomizationsActions: RefluxActions<ActionsType> = singletonActions('Customizations', () => Reflux.createActions({
  updateTheme: { asyncResult: true },
  loadTheme: { asyncResult: true },
  createPublicNotification: { asyncResult: true },
  updatePublicNotification: { asyncResult: true },
  loadPublicNotification: { asyncResult: true },
  loadAllPublicNotifications: { asyncResult: true },
  deletePublicNotification: { asyncResult: true },
  getLicenseStatus: { asyncResult: true },
}));

export default CustomizationsActions;
