// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3LbVv5KKMy9JoRpdXexKP2 {\n    padding: 0;\n    overflow-y: auto;\n}\n\n._3GOjLTU4tC7AIovCKZBL0V {\n    font-size: 12px;\n    display: table-row;\n    white-space: nowrap;\n}\n\n._2JI80-VOMBDGL-LNQJ4Ubt {\n    display: flex;\n}\n\n.FF3hs0O7q13mSqZXYBtiV {\n    flex-grow: 1;\n    margin-right: 5px;\n}", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/views/components/sidebar/fields/FieldTypeIcon.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[":local(.fieldList) {\n    padding: 0;\n    overflow-y: auto;\n}\n\n:local(.fieldListItem) {\n    font-size: 12px;\n    display: table-row;\n    white-space: nowrap;\n}\n\n:local(.filterContainer) {\n    display: flex;\n}\n\n:local(.filterInputContainer) {\n    flex-grow: 1;\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldList": "_3LbVv5KKMy9JoRpdXexKP2",
	"fieldListItem": "_3GOjLTU4tC7AIovCKZBL0V",
	"filterContainer": "_2JI80-VOMBDGL-LNQJ4Ubt",
	"filterInputContainer": "FF3hs0O7q13mSqZXYBtiV"
};
export default ___CSS_LOADER_EXPORT___;
