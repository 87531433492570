// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XZYFMCRm0i4LOjZW06aW4 {\n    margin-bottom: 10px;\n}\n\n.XZYFMCRm0i4LOjZW06aW4 img {\n    border: 1px #e3e3e3 solid;\n}\n\n._2po_83yFXWHs1fWKlXcgV5 {\n    margin-bottom: 10px;\n}", "",{"version":3,"sources":["webpack://./src/web/report/report-contents-page/ReportContentsSelection.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[":local(.logo) {\n    margin-bottom: 10px;\n}\n\n:local(.logo) img {\n    border: 1px #e3e3e3 solid;\n}\n\n:local(.removeLogo) {\n    margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "XZYFMCRm0i4LOjZW06aW4",
	"removeLogo": "_2po_83yFXWHs1fWKlXcgV5"
};
export default ___CSS_LOADER_EXPORT___;
