import * as React from 'react';
import { useContext, useState } from 'react';
import styled, { css } from 'styled-components';

import { isPermitted } from 'util/PermissionsMixin';
import CurrentUserContext from 'contexts/CurrentUserContext';
import { Button } from 'components/graylog';
import Spinner from 'components/common/Spinner';
import ConfirmDialog from 'components/common/ConfirmDialog';
import ConfirmLeaveDialog from 'components/common/ConfirmLeaveDialog';
import usePluginEntities from 'views/logic/usePluginEntities';

import ThemeSection from './ThemeSection';

const Header = styled.h4`
  margin: 15px 0 3px;
`;

const SectionWrap = styled.ul(({ theme }) => css`
  margin: 0;
  padding: 0 0 18px;
  list-style: none;
  
  li {
    padding: 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(even) {
      background-color: ${theme.colors.variant.lightest.default};
    }

    > span {
      flex: 5;
    }
    
    > div,
    > strong {
      flex: 1;
      text-align: center;
    }
  }
`);

const StyledButton = styled(Button)`
  margin: 0 12px;
`;

const ThemeEditor = () => {
  const [showConfirmRevert, setShowConfirmRevert] = useState<boolean>();
  const [showConfirmReset, setShowConfirmReset] = useState<boolean>();
  const themeCustomizer = usePluginEntities('customization.theme.customizer');
  const useThemeCustomizer = themeCustomizer?.[0]?.hooks?.useThemeCustomizer;
  const currentUser = useContext(CurrentUserContext);
  const {
    currentColors,
    isDefaultColors,
    isSaved,
    onChangeTheme,
    onResetTheme,
    onRevertTheme,
    onSaveTheme,
  } = useThemeCustomizer();

  const toggleConfirmRevert = () => {
    setShowConfirmRevert(!showConfirmRevert);
  };

  const toggleConfirmReset = () => {
    setShowConfirmReset(!showConfirmReset);
  };

  const handleRevertTheme = () => {
    onRevertTheme().then(toggleConfirmRevert);
  };

  const handleResetTheme = () => {
    onResetTheme().then(toggleConfirmReset);
  };

  if (!isPermitted(currentUser?.permissions, 'clusterconfigentry:edit')) { // TODO: find/create a better perms role
    return null;
  }

  return currentColors ? (
    <div>
      <Header id="themeGeneralSection">General</Header>
      <SectionWrap title="modify global theme general colors">
        <li><span /><strong>Light</strong><strong>Dark</strong><span /></li>
        <ThemeSection currentThemeColors={currentColors} onChange={onChangeTheme} section="general" />
      </SectionWrap>

      <Header id="themeVariantsSection">Variants</Header>
      <SectionWrap title="modify global theme variant colors">
        <li><span /><strong>Light</strong><strong>Dark</strong><span /></li>
        <ThemeSection currentThemeColors={currentColors} onChange={onChangeTheme} section="variant" />
      </SectionWrap>

      <Button onClick={toggleConfirmRevert} bsStyle="warning" bsSize="xs" disabled={isSaved} title="Revert to the Previous Save">Revert</Button>
      <StyledButton onClick={toggleConfirmReset} bsStyle="danger" bsSize="xs" disabled={isDefaultColors} title="Reset to Graylog Default Colors">Reset</StyledButton>
      <Button onClick={onSaveTheme} bsStyle="success" bsSize="xs" disabled={isSaved} title="Save Custom Theme">Save Theme</Button>

      <ConfirmDialog show={showConfirmRevert} onConfirm={handleRevertTheme} onCancel={toggleConfirmRevert} title="Are you sure?">
        <>
          <p>Reverting your theme to the last save will lose any unsaved changes.</p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>

      <ConfirmDialog show={showConfirmReset} onConfirm={handleResetTheme} onCancel={toggleConfirmReset} title="Are you sure?">
        <>
          <p>Resetting your theme will remove all your custom changes and load in default Graylog colors.</p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>

      {!isSaved && <ConfirmLeaveDialog question="You have unsaved Theme changes. Are you sure you want to abandon them?" />}
    </div>
  ) : <Spinner />;
};

export default ThemeEditor;
