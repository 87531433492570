import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'components/graylog/router';
import { Alert, Col, Row } from 'components/graylog';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import AppConfig from 'util/AppConfig';
import { LicenseStatusPropType } from 'archive/propTypes';

import type { LicenseStatus as LicenseStatusType } from '../types';

const isCloud = AppConfig.isCloud();

const StyledH4 = styled.h4`
  font-weight: bold;
  margin-bottom: 5px;
`;

const LicenseStatus = () => (
  <Alert bsStyle="danger">
    <StyledH4>Archive creation is disabled</StyledH4>
    <p>
      Archive creation is disabled because there is no valid license.
      You can still restore existing archives, but Graylog will not create new ones.
    </p>
    {isCloud
      ? (<>Contact your Graylog account manager.</>)
      : (
        <IfPermitted permissions="licenses:create">
          <p>
            See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
          </p>
        </IfPermitted>
      )}
  </Alert>
);

type Props = {
  licenseStatus: LicenseStatusType,
  summary?: boolean,
  rowClass?: string,
  colWidth?: number,
};

const ArchiveLicenseStatus = ({ licenseStatus, summary, rowClass, colWidth }: Props) => {
  const { status, missing } = licenseStatus;

  if (!missing && (status && status.valid)) {
    return null;
  }

  if (summary) {
    return <LicenseStatus />;
  }

  return (
    <Row className={rowClass}>
      <Col md={colWidth}>
        <LicenseStatus />
      </Col>
    </Row>
  );
};

ArchiveLicenseStatus.propTypes = {
  licenseStatus: LicenseStatusPropType.isRequired,
  colWidth: PropTypes.number,
  rowClass: PropTypes.string,
  summary: PropTypes.bool,
};

ArchiveLicenseStatus.defaultProps = {
  colWidth: 12,
  rowClass: 'content',
  summary: false,
};

export default ArchiveLicenseStatus;
