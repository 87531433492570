import * as React from 'react';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import history from 'util/History';

import LicenseCheckContext from 'license/LicenseCheckContext';

import IlluminateInstall from './IlluminateInstall';
import BundleProvider from './context/BundleContext';
import IlluminateBundle from './IlluminateBundle';
import IlluminateLicense from './IlluminateLicense';

const IlluminatePage = () => {
  const { illuminate: illuminateLicenseIsValid } = useContext(LicenseCheckContext);
  const { pathname } = useLocation();
  const [installing, setInstalling] = useState<boolean>(pathname === '/illuminate/install');

  const goToInstall = () => {
    setInstalling(true);
    history.replace('/illuminate/install');
  };

  const goToBundle = () => {
    setInstalling(false);
    history.push('/illuminate');
  };

  if (!illuminateLicenseIsValid) {
    return <IlluminateLicense />;
  }

  return (
    <BundleProvider>
      {installing ? (
        <IlluminateInstall goToBundle={goToBundle} />
      ) : (
        <IlluminateBundle goToInstall={goToInstall} />
      )}
    </BundleProvider>
  );
};

export default IlluminatePage;
