import * as React from 'react';
import { useContext } from 'react';
import { AdditionalContext } from 'views/logic/ActionContext';
import type { Message } from 'views/components/messagelist/Types';

import LicenseCheckContext from 'license/LicenseCheckContext';
import WatchlistStatusProvider from 'watchlist/contexts/WatchlistStatusProvider';
import WatchlistStatusContext from 'watchlist/contexts/WatchlistStatusContext';

type Props = {
  children: React.ReactNode
  message: Message,
};

const MessageDetailsAdditionalProvider = ({ children, message }: Props) => {
  const licenseCheck = useContext(LicenseCheckContext);

  return (
    <WatchlistStatusProvider message={message}>
      <WatchlistStatusContext.Consumer>
        {(watchlistStatus) => (
          <AdditionalContext.Provider value={{ licenseCheck, watchlistStatus }}>
            {children}
          </AdditionalContext.Provider>
        )}
      </WatchlistStatusContext.Consumer>
    </WatchlistStatusProvider>
  );
};

export default MessageDetailsAdditionalProvider;
