import {
  BackendConfig,
  BackendConfigJson,
  OidcBackendConfig,
  OidcBackendConfigJson,
  OktaBackendConfig,
  OktaBackendConfigJson,
} from 'logic/authentication/okta/types';

import { OKTA_TYPE_KEY } from './constants';

export function fromJson(input: BackendConfigJson): BackendConfig {
  const baseUrl = input.type === OKTA_TYPE_KEY
    ? (input as Omit<OktaBackendConfigJson, 'clientSecret'>).okta_base_url
    : (input as Omit<OidcBackendConfigJson, 'clientSecret'>).base_url;

  return {
    type: input.type,
    ...(input.type === OKTA_TYPE_KEY) ? { oktaBaseUrl: baseUrl } : { baseUrl: baseUrl },
    clientId: input.client_id,
    clientSecret: input.client_secret,
    tokenVerifierConnectTimeout: input.token_verifier_connect_timeout,
    callbackUrl: input.callback_url,
  };
}

export function toJson(input: BackendConfig): BackendConfigJson {
  const baseUrl = input.type === OKTA_TYPE_KEY
    ? (input as Omit<OktaBackendConfig, 'clientSecret'>).oktaBaseUrl
    : (input as Omit<OidcBackendConfig, 'clientSecret'>).baseUrl;

  return {
    type: input.type,
    ...(input.type === OKTA_TYPE_KEY) ? { okta_base_url: baseUrl } : { base_url: baseUrl },
    client_id: input.clientId,
    client_secret: input.clientSecret,
    token_verifier_connect_timeout: input.tokenVerifierConnectTimeout,
    callback_url: input.callbackUrl,
  };
}
