import * as React from 'react';
import { useContext } from 'react';

import SectionComponent from 'components/common/Section/SectionComponent';
import { ControlledTableList, Spinner } from 'components/common';
import LicenseCheckContext from 'license/LicenseCheckContext';

import IlluminatePackItem from './IlluminatePackItem';
import IlluminateBulkActions from './IlluminateBulkActions';
import { PackContext } from './context/PackContext';

const IlluminatePacks = () => {
  const { illuminate: illuminateLicenseIsValid } = useContext(LicenseCheckContext);
  const { packs, packsLoading, selectedPackIds, setSelectedPackIds, updatePacks } = useContext(PackContext);

  const handleTickPackItem = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedPackIds([...selectedPackIds, id]);
    } else {
      setSelectedPackIds(selectedPackIds.filter((packId) => packId !== id));
    }
  };

  const handleTickAllPacks = ({ currentTarget }) => {
    if (currentTarget.indeterminate || currentTarget.checked) {
      const allPackIds = packs.map((pack) => pack.pack_id);

      setSelectedPackIds(allPackIds);
    } else {
      setSelectedPackIds([]);
    }
  };

  const toggleEnabled = (enabled: boolean) => {
    return updatePacks({ enabled }).then(() => {
      setSelectedPackIds([]);
    });
  };

  return (
    <SectionComponent title="">
      {packsLoading ? (
        <Spinner text="Loading Illuminate Packs" />
      ) : (
        <ControlledTableList>
          <ControlledTableList.Header>
            {illuminateLicenseIsValid ? (
              <IlluminateBulkActions onChange={handleTickAllPacks}
                                     onConfirm={toggleEnabled} />
            ) : null}

          </ControlledTableList.Header>

          {packs.map((packItem) => (
            <IlluminatePackItem key={packItem.pack_id}
                                packItem={packItem}
                                onChange={handleTickPackItem} />
          ))}
        </ControlledTableList>
      )}
    </SectionComponent>
  );
};

export default IlluminatePacks;
