import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { css, DefaultTheme } from 'styled-components';

import { LinkContainer } from 'components/graylog/router';
import { Icon, IfPermitted, PaginatedList, SearchForm, Spinner } from 'components/common';
import { Button, ButtonGroup, Col, OverlayTrigger, Popover, Row, Table } from 'components/graylog';
import Routes from 'routing/Routes';

import ForwarderEntry from './ForwarderEntry';

import {
  Forwarder,
  ForwarderPagination,
  ForwarderPaginationPropType,
  ForwarderPropType,
  ForwarderStateFilter,
  InputProfile,
  InputProfilePropType,
  SortOrder,
} from '../Types';
import { StyledSearchFormWrapper, StyledToolbar } from '../wizard/StyledWizardComponents';
import { QueryHelpButton, SortableIcon, SortableTH } from '../CommonStyledComponents';

const StyledTable = styled(Table)`
tr > th {
  width: 15%;
  
  &.rowTitle {
    width: 25%;
  }
  &.rowStatus {
    width: 120px;
    min-width: 120px;

  }
  &.rowDescription {
    width: 20%;
  }
  &.rowActions {
    width: 160px;
    min-width: 160px;
  }
  &.rowMetrics {
    width: 10%;
  }
}
`;

const ResponsiveTable = styled.div.attrs(() => ({
  className: 'table-responsive',
}))(({ theme }: {theme: DefaultTheme}) => css`
  @media (max-width: ${theme.breakpoints.max.md}) {
    .dropdown-menu {
      position: static !important;
    }
  }
  @media (min-width: ${theme.breakpoints.min.md}) {
    overflow: inherit;
  }
`);

const StyledPopover = styled(Popover)`
  max-width: 500px;
`;

const StyledSubHeader = styled.span`
  margin-left: 0.5em;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 10px;
`;

type Props = {
  forwarders: Array<Forwarder>,
  inputProfiles: Array<InputProfile>,
  isLoading: boolean,
  pagination: ForwarderPagination,
  onQueryChange: (page?: number, perPage?: number, query?: string) => void,
  onSortChange: (sortByField: string, order: SortOrder) => void,
  onStateFilterChange: (stateFilter: ForwarderStateFilter) => void,
};

const ForwardersListComponent = ({ forwarders, inputProfiles, isLoading, onQueryChange, onSortChange, onStateFilterChange, pagination }: Props) => {
  const { total, perPage, page, query, sortByField, order, stateFilter } = pagination;

  const _getForwardersTable = () => {
    if (forwarders.length === 0) {
      return (
        <tr>
          <td colSpan={100}>No Forwarders match your search criteria.</td>
        </tr>
      );
    }

    return forwarders.map((forwarder) => {
      const inputProfile = inputProfiles.find((i) => i.id === forwarder.input_profile_id);

      return (
        <ForwarderEntry key={forwarder.id}
                        forwarder={forwarder}
                        inputProfile={inputProfile || null} />
      );
    });
  };

  const _handleOnSearch = (nextQuery: string) => onQueryChange(1, 10, nextQuery);

  const _handleSearchReset = () => onQueryChange();

  const _handleOnPaginationChange = (nextPage: number, nextPageSize: number) => {
    onQueryChange(nextPage, nextPageSize, query);
  };

  const _handleSortChange = (field) => {
    let nextOrder: SortOrder = 'asc';

    if (sortByField === field) {
      nextOrder = order === 'asc' ? 'desc' : 'asc';
    }

    onSortChange(field, nextOrder);
  };

  const _handleStateFilterChange = (event) => {
    onStateFilterChange(event.target.name);
  };

  const _helpPopover = () => {
    return (
      <StyledPopover id="search-query-help" title="Search Syntax Help">
        <p><strong>Available search fields</strong></p>
        <Table condensed>
          <thead>
            <tr>
              <th>Field</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>title</td>
              <td>The title of the Forwarder</td>
            </tr>
            <tr>
              <td>description</td>
              <td>The description of Forwarder</td>
            </tr>
            <tr>
              <td>hostname</td>
              <td>The hostname of Forwarder</td>
            </tr>
          </tbody>
        </Table>
        <p><strong>Examples</strong></p>
        <p>
          Find Forwarder by parts of their title:&nbsp;
          <kbd>title:local</kbd>&nbsp;
          <kbd>title:database</kbd>
        </p>
        <p>
          Searching without a field name matches against the <code>title</code> field: &nbsp;
          <kbd>local</kbd> is the same as &nbsp;
          <kbd>title:local</kbd>
        </p>
      </StyledPopover>
    );
  };

  const sortIconNames = {
    asc: 'sort-amount-down',
    desc: 'sort-amount-up',
  };

  const tableHeaders = [
    { sortable: true, field: 'title', label: 'Title', className: 'rowTitle' },
    { sortable: true, field: 'state', label: 'Status', className: 'rowStatus' },
    { sortable: true, field: 'description', label: 'Description', className: 'rowDescription' },
    { sortable: true, field: 'hostname', label: 'Hostname', className: 'rowHostname' },
    { sortable: false, field: undefined, label: 'Input Profile', className: 'rowInputProfile' },
    { sortable: false, field: undefined, label: 'Metrics', className: 'rowMetrics' },
    { sortable: false, field: undefined, label: 'Actions', className: 'rowActions' },
  ];

  const queryHelp = (
    <OverlayTrigger trigger="click" rootClose placement="right" overlay={_helpPopover()}>
      <QueryHelpButton bsStyle="link"><Icon name="question-circle" fixedWidth /></QueryHelpButton>
    </OverlayTrigger>
  );

  return (
    <div>
      <Row className="content">
        <Col md={12}>
          <h2>
            Forwarders
            <small>
              <StyledSubHeader>{`${total} Total`}</StyledSubHeader>
              <StyledSubHeader>{isLoading && <Spinner text="" />}</StyledSubHeader>
            </small>
          </h2>
          <StyledSearchFormWrapper>
            <SearchForm query={query}
                        queryWidth={400}
                        queryHelpComponent={queryHelp}
                        onSearch={_handleOnSearch}
                        onReset={_handleSearchReset}>
              <StyledToolbar>
                <IfPermitted permissions="forwarders:create">
                  <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS_NEW')}>
                    <Button bsStyle="success">New Forwarder</Button>
                  </LinkContainer>
                </IfPermitted>
              </StyledToolbar>
            </SearchForm>
          </StyledSearchFormWrapper>

          <StyledButtonGroup>
            <Button active={stateFilter === 'connected'} name="connected" bsSize="sm" onClick={_handleStateFilterChange}>
              Connected
            </Button>
            <Button active={stateFilter === 'disconnected'} name="disconnected" bsSize="sm" onClick={_handleStateFilterChange}>
              Disconnected
            </Button>
            <Button active={stateFilter === 'any'} name="any" bsSize="sm" onClick={_handleStateFilterChange}>
              Any
            </Button>
          </StyledButtonGroup>

          <PaginatedList totalItems={total}
                         activePage={page}
                         pageSize={perPage}
                         onChange={_handleOnPaginationChange}
                         showPageSizeSelect>
            <ResponsiveTable>
              <StyledTable hover>
                <thead>
                  <tr>
                    {tableHeaders.map(({ sortable, field, label, className }) => (sortable && field
                      ? (
                        <SortableTH key={field}
                                    className={className}
                                    onClick={() => _handleSortChange(field)}
                                    sorted={sortByField === field}>
                          {label} <SortableIcon name={sortByField === field ? sortIconNames[order] : 'sort'} />
                        </SortableTH>
                      ) : (
                        <th key={label} className={className}>
                          {label}
                        </th>
                      )))}
                  </tr>
                </thead>
                <tbody>
                  {_getForwardersTable()}
                </tbody>
              </StyledTable>
            </ResponsiveTable>
          </PaginatedList>
        </Col>
      </Row>
    </div>
  );
};

ForwardersListComponent.propTypes = {
  forwarders: PropTypes.arrayOf(ForwarderPropType).isRequired,
  inputProfiles: PropTypes.arrayOf(InputProfilePropType).isRequired,
  isLoading: PropTypes.bool,
  pagination: ForwarderPaginationPropType.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
};

ForwardersListComponent.defaultProps = {
  isLoading: false,
};

export default ForwardersListComponent;
