import * as React from 'react';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { DocumentTitle } from 'components/common';
import { Button, Panel } from 'components/graylog';
import PageHeader from 'components/common/PageHeader';
import SectionComponent from 'components/common/Section/SectionComponent';
import SectionGrid from 'components/common/Section/SectionGrid';

import { Warning as LicenseCheckWarning } from 'license/LicenseCheck';
import LicenseCheckContext from 'license/LicenseCheckContext';

import DragDropUpload from './DragDropUpload';
import { IlluminateActions } from './stores/IlluminateStore';
import { BundleContext } from './context/BundleContext';
import { Bundle } from './types';

const ReadMeText = styled.p`
  max-width: 60em;
`;

const DragDropBlock = ({ onUpload }: { onUpload: (version: Bundle['version']) => void }) => {
  const { illuminate: illuminateLicenseIsValid } = useContext(LicenseCheckContext);

  return illuminateLicenseIsValid ? (
    <DragDropUpload accept={['application/zip', 'application/x-zip-compressed']}
                    maxFiles={1}
                    message={<span>Drag & Drop your Illuminate package here, <br />or click to choose manually</span>}
                    uploadAction={IlluminateActions.uploadBundle}
                    onComplete={onUpload} />

  ) : (
    <Panel bsStyle="warning">
      <LicenseCheckWarning licenseSubject="/license/enterprise/illuminate" featureName="Illuminate" />
    </Panel>
  );
};

const IlluminateInstall = ({ goToBundle }: { goToBundle: () => void }) => {
  const { bundleVersion, setBundleVersion } = useContext(BundleContext);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [nextVersion, setNextVersion] = useState<Bundle['version']>();

  const onUpload = (version) => {
    if (!bundleVersion) {
      setBundleVersion(version);
      goToBundle();
    } else {
      setNextVersion(version);
      setShowSuccess(true);
    }
  };

  return (
    <DocumentTitle title="Illuminate Install">
      <PageHeader title="Illuminate Install">
        <span>
          Begin your installation of the Illuminate package by following the directions below.
        </span>
      </PageHeader>

      <SectionComponent title="Bundle Upload">
        <SectionGrid $columns="2fr 1fr">
          <ReadMeText>Create a foundation to normalize all authentication data, regardless of source. This gives you consistency in reporting, alerting, and analysis plus the power to easily correlate authentication data across different types of data sources.</ReadMeText>

          {showSuccess ? (
            <Panel bsStyle="success">
              <Panel.Heading>
                <Panel.Title>Illuminate {nextVersion} Successfully Uploaded </Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <p><strong>Your current Illuminate version {bundleVersion} is still active.</strong></p>
                <p>In order to update your Graylog instance to another Illuminate Bundle, you will need to choose the version you would like to use in the header of the Illuminate Packs Manager.</p>
                <p><Button onClick={goToBundle}>Continue to Packs Manager</Button></p>
              </Panel.Body>
            </Panel>
          ) : (
            <DragDropBlock onUpload={onUpload} />
          )}

        </SectionGrid>
      </SectionComponent>
    </DocumentTitle>
  );
};

export default IlluminateInstall;
