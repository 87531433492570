// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ucu_oiCxxCgIF-aTrXBc6 .panel-title {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n\n.ucu_oiCxxCgIF-aTrXBc6 .panel-title > a {\n    cursor: pointer;\n    display: block;\n}\n\n.ucu_oiCxxCgIF-aTrXBc6 .panel-body {\n    padding: 10px;\n}\n\n.ucu_oiCxxCgIF-aTrXBc6 ul {\n    padding-left: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/event-definitions/common/HelpPanel.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,0BAA0B;AAC/C;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[":local(.helpPanel) .panel-title {\n    font-size: 1rem; /* theme.fonts.size.body */\n}\n\n:local(.helpPanel) .panel-title > a {\n    cursor: pointer;\n    display: block;\n}\n\n:local(.helpPanel) .panel-body {\n    padding: 10px;\n}\n\n:local(.helpPanel) ul {\n    padding-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpPanel": "ucu_oiCxxCgIF-aTrXBc6"
};
export default ___CSS_LOADER_EXPORT___;
