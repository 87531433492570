import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';

import { Button, Row, Col } from 'components/graylog';
import { Input } from 'components/bootstrap';

import Team from '../../logic/Team';

type Props = {
  onClose: () => void,
  onSubmit: (team: Team) => Promise<Team>,
};

const Container = styled.div`
  padding: 15px;
`;

const SubmitButton = styled(Button)`
  margin-left: 5px;
`;

const TeamQuickCreate = ({ onClose, onSubmit }: Props) => {
  const _onSubmit = ({ name, description }: { name: string, description: string }, { resetForm }) => {
    const newTeam = Team.builder().name(name).description(description).build();

    return onSubmit(newTeam).then(() => {
      resetForm();
      onClose();
    });
  };

  return (
    <Container>
      <Formik onSubmit={_onSubmit}
              initialValues={{ name: '', description: '' }}>
        {({ isSubmitting, isValid }) => (
          <Form className="form form-horizontal">
            <Field name="name">
              {({ field: { value, onChange } }) => (
                <Input type="text"
                       id="name"
                       label="Name"
                       required
                       maxLength={200}
                       onChange={onChange}
                       help="A unique name for the team"
                       labelClassName="col-sm-3"
                       wrapperClassName="col-sm-9"
                       value={value} />
              )}
            </Field>
            <Field name="description">
              {({ field: { value, onChange } }) => (
                <Input type="text"
                       id="description"
                       label="Description"
                       required
                       onChange={onChange}
                       help="A description about the team"
                       labelClassName="col-sm-3"
                       wrapperClassName="col-sm-9"
                       value={value} />
              )}
            </Field>
            <Row>
              <Col xs={9} xsOffset={3}>
                <Button disabled={isSubmitting}
                        title="Cancel"
                        onClick={onClose}>
                  Cancel
                </Button>
                <SubmitButton bsStyle="success"
                              disabled={isSubmitting || !isValid}
                              title="Create Team"
                              type="submit">
                  Create Team
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default TeamQuickCreate;
