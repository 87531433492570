import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';
import { useStore } from 'stores/connect';
import { Col, Row } from 'components/graylog';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';

import ReportConfiguration from './ReportConfiguration';

import ReportHeaderToolbar from '../common/ReportHeaderToolbar';
import ReportValidations from '../common/ReportValidations';
import ReportsStore from '../ReportsStore';
import ReportsActions from '../ReportsActions';

type Props = {
  params: {
    reportId: string,
  },
};

const ReportConfigurationPage = ({ params: { reportId } }: Props) => {
  const { report, reportLogo } = useStore(ReportsStore);

  useEffect(() => {
    ReportsActions.get(reportId);
    ReportsActions.getReportLogo(reportId);
  }, [reportId]);

  const isLoading = !report;

  if (isLoading) {
    return <Spinner text="Loading report data, please wait..." />;
  }

  return (
    <DocumentTitle title={`Report ${report.title} configuration`}>
      <div>
        <PageHeader title={<span>Report <em>{report.title}</em> configuration</span>}>
          <span>
            Configure the report layout and schedule, adapting it to your needs.
          </span>
          {null}
          <ReportHeaderToolbar report={report} active="REPORTS_REPORTID_CONFIGURATION" />
        </PageHeader>

        <ReportValidations />

        <Row className="content">
          <Col md={12}>
            <ReportConfiguration report={report} reportLogo={reportLogo} />
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

ReportConfigurationPage.propTypes = {
  params: PropTypes.exact({
    reportId: PropTypes.string.isRequired,
  }).isRequired,
};

export default withParams(ReportConfigurationPage);
