// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._91AxbbptaQXNAMecz10H1 {\n    font-size: 14px;\n    margin-top: 15px;\n    width: 95%;\n}\n\n._1sTKZobDXsPhKajPBYyiav {\n    white-space: pre-wrap;\n}\n\n._2op2cbXH41lrR8DF2wOqpu img {\n    border: 1px #e3e3e3 solid;\n}", "",{"version":3,"sources":["webpack://./src/web/report/report-contents-page/ReportSummary.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[":local(.reportPreview) {\n    font-size: 14px;\n    margin-top: 15px;\n    width: 95%;\n}\n\n:local(.descriptionPreview) {\n    white-space: pre-wrap;\n}\n\n:local(.logo) img {\n    border: 1px #e3e3e3 solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportPreview": "_91AxbbptaQXNAMecz10H1",
	"descriptionPreview": "_1sTKZobDXsPhKajPBYyiav",
	"logo": "_2op2cbXH41lrR8DF2wOqpu"
};
export default ___CSS_LOADER_EXPORT___;
