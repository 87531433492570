const API_PLUGIN_ROUTE = '/plugins/org.graylog.plugins';

const EnterpriseApiRoutes = {
  Illuminate: {
    listPacks: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
    updatePacks: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}/packs` }),
    listBundles: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles` }),
    updateBundle: (bundleId: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${bundleId}` }),
    deleteBundle: (version: string) => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/${version}` }),
    uploadBundle: () => ({ url: `${API_PLUGIN_ROUTE}.illuminate/bundles/upload` }),
  },
};

export default EnterpriseApiRoutes;
