import * as React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';

import fixup from 'views/pages/StyleFixups.css';
import { Col, Row } from 'components/graylog';
import { ParameterMap } from 'views/logic/parameters/Parameter';
import PageContentLayout from 'components/layout/PageContentLayout';
import View from 'views/logic/views/View';

import ParameterInputForm from './ParameterInputForm';

const humanReadableViewType = (viewType: string) => capitalize(viewType);

type Props = {
  onSubmit: () => void,
  parameters: ParameterMap,
  view: View,
}

const RequiredParametersForViewForm = ({ onSubmit, parameters, view: { type, title, search } }: Props) => (
  <PageContentLayout>
    <Row className="content">
      <Col md={8} mdOffset={3} className={fixup.bootstrapHeading}>
        <h1>{humanReadableViewType(type)}: <em>{title}</em></h1>
        <p>This {humanReadableViewType(type).toLowerCase()} uses parameters which need to be entered before it can be executed. Please enter the parameter values into the form.</p>
      </Col>
      <ParameterInputForm parameters={parameters}
                          searchId={search.id}
                          onSubmit={onSubmit} />
    </Row>
  </PageContentLayout>
);

RequiredParametersForViewForm.propTypes = {
  onSubmit: PropTypes.func,
  parameters: PropTypes.any.isRequired,
  view: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    search: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

RequiredParametersForViewForm.defaultProps = {
  onSubmit: () => {},
};

export default RequiredParametersForViewForm;
