import * as React from 'react';
import { createContext, useState, Dispatch, SetStateAction, useEffect } from 'react';

import { IlluminateActions } from '../stores/IlluminateStore';
import { Bundle, Bundles } from '../types';

type BundleContextValues = {
  bundleVersion: Bundle['version'],
  setBundleVersion: Dispatch<SetStateAction<Bundle['version']>>,
  bundles: Bundles
  setBundles: Dispatch<SetStateAction<Bundles>>,
  setBundleLoading: Dispatch<SetStateAction<boolean>>,
  bundleLoading: boolean,
}

const DEFAULT_BUNDLE = [];

export const BundleContext = createContext<BundleContextValues>({
  bundleVersion: undefined,
  setBundleVersion: undefined,
  setBundleLoading: undefined,
  setBundles: undefined,
  bundles: DEFAULT_BUNDLE,
  bundleLoading: true,
});

const BundleProvider = ({ children }: {children: React.ReactNode}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [bundles, setBundles] = useState<BundleContextValues['bundles']>(DEFAULT_BUNDLE);
  const [bundleVersion, setBundleVersion] = useState<BundleContextValues['bundleVersion']>();

  useEffect(() => {
    if (loading) {
      IlluminateActions.listBundles().then((bundleList) => {
        if (bundleList?.length) {
          const serverBundleVersion = bundleList.filter((bundle) => bundle.enabled).pop().version;
          setBundles(bundleList);
          setBundleVersion(serverBundleVersion);
        }

        setLoading(false);

        return bundleList;
      });
    }
  }, [loading]);

  return (
    <BundleContext.Provider value={{ bundleLoading: loading, setBundleLoading: setLoading, bundleVersion, bundles, setBundleVersion, setBundles }}>
      {children}
    </BundleContext.Provider>
  );
};

export default BundleProvider;
