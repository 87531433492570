import Reflux from 'reflux';
import * as Immutable from 'immutable';

import URLUtils from 'util/URLUtils';
import fetch from 'logic/rest/FetchProvider';
import FieldType from 'views/logic/fieldtypes/FieldType';
import Parameter from 'views/logic/parameters/Parameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import Widget from 'views/logic/widgets/Widget';
import WidgetConfig from 'views/logic/widgets/WidgetConfig';
import type { RefluxActions } from 'stores/StoreTypes';

type WidgetSummary = {
  id: string,
  title: string,
  type: string,
  config: WidgetConfig,
  eligible: boolean,
};
type DashboardSummary = {
  id: string,
  title: string,
  widgets: Array<WidgetSummary>,
};
type WidgetResult = {
  result: Array<any>,
  calculated_at: Date,
  field_types: Array<FieldType>,
};

type AvailableWidgetsActionsType = RefluxActions<{
  list: () => Promise<Array<DashboardSummary>>,
  value: (dashboardId: string, widgetId: string, parameterBindings: { [key: string]: ParameterBinding }) => Promise<WidgetResult>,
}>;

const AvailableWidgetsActions: AvailableWidgetsActionsType = Reflux.createActions({
  list: { asyncResult: true },
  value: { asyncResult: true },
});

const AvailableWidgetsStore = Reflux.createStore({
  listenables: [AvailableWidgetsActions],
  sourceUrl: '/plugins/org.graylog.plugins.report',

  dashboards: undefined,

  getInitialState() {
    return {
      dashboards: this.dashboards,
    };
  },

  list() {
    const promise = fetch('GET', URLUtils.qualifyUrl(`${this.sourceUrl}/widgets`))
      .then((dashboards) => {
        this.dashboards = Immutable.List(
          dashboards.map(({ widgets, ...rest }) => ({
            ...rest,
            widgets: widgets.map((widget) => ({
              id: widget.id,
              title: widget.title,
              type: widget.type,
              config: Widget.fromJSON(widget).config,
              streams: widget.streams,
              parameters: widget.parameters.map(Parameter.fromJSON),
              eligible: widget.eligible,
            })),
          })),
        );

        this._trigger();

        return this._state();
      });
    AvailableWidgetsActions.list.promise(promise);

    return promise;
  },

  value(dashboardId, widgetId, parameterBindings, globalOverride = {}) {
    const request = {
      parameter_bindings: Object.entries(parameterBindings)
        .map(([key, value]): [string, ParameterBinding] => [key, ParameterBinding.create('value', value)])
        .reduce((prev, [key, binding]) => ({ ...prev, [key]: binding }), {}),
      global_override: globalOverride,
    };
    const promise = fetch('POST', URLUtils.qualifyUrl(`${this.sourceUrl}/widgets/${dashboardId}/${widgetId}/value`), request);
    AvailableWidgetsActions.value.promise(promise);

    return promise;
  },

  _trigger() {
    this.trigger(this._state());
  },
  _state() {
    return {
      dashboards: this.dashboards,
    };
  },
});

export { AvailableWidgetsActions, AvailableWidgetsStore };
