import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'components/graylog/router';
import { DocumentTitle, PageHeader, Spinner } from 'components/common';
import withParams from 'routing/withParams';
import { Button, ButtonToolbar, Col, Row } from 'components/graylog';
import Routes from 'routing/Routes';
import DocumentationLink from 'components/support/DocumentationLink';

import ForwarderDocs from 'forwarder/util/ForwarderDocs';

import ForwarderInputProfile from './forwarders/ForwarderInputProfile';
import { ForwardersActions } from './stores/ForwardersStore';
import ForwarderDetailsComponent from './forwarders/ForwarderDetailsComponent';
import SingleForwarderProvider from './forwarders/SingleForwarderProvider';
import ForwardersContext from './forwarders/ForwardersContext';
import withIsPermitted from './util/withIsPermitted';
import { Forwarder } from './Types';

type Props = {
  params: {
    forwarderId: string,
  },
};

const requiredPermissions = ({ params }) => [`forwarders:read:${params.forwarderId}`, 'inputprofiles:read', 'forwarderinputs:read'];

const ForwarderPage = ({ params }: Props) => {
  const _handleInputProfileSwitch = (forwarder: Forwarder) => (nextInputProfileId: string) => {
    const updatedForwarder = {
      input_profile_id: nextInputProfileId,
      title: forwarder.title,
      description: forwarder.description,
    };

    ForwardersActions.update(forwarder.id, updatedForwarder).then(() => {
      ForwardersActions.get(params.forwarderId);
    });
  };

  return (
    <SingleForwarderProvider forwarderId={params.forwarderId}>
      <ForwardersContext.Consumer>
        {({ forwarders }) => {
          const forwarder = forwarders[0];

          if (!forwarder) {
            return <Spinner />;
          }

          return (
            <DocumentTitle title={`Forwarder: ${forwarder.title}`}>
              <PageHeader title={`Forwarder: ${forwarder.title}`}>
                <span>Overview of {forwarder.title} Forwarder, including the Input Profile it is using.</span>
                <span>
                  Graylog Forwarder&nbsp;
                  <DocumentationLink text="documentation" page={ForwarderDocs.FORWARDER} />.
                </span>
                <ButtonToolbar>
                  <LinkContainer to={Routes.pluginRoute('SYSTEM_FORWARDERS')}>
                    <Button bsStyle="info">Forwarders</Button>
                  </LinkContainer>
                  <LinkContainer to={Routes.pluginRoute('SYSTEM_INPUT_PROFILES')}>
                    <Button bsStyle="info">Input Profiles</Button>
                  </LinkContainer>
                </ButtonToolbar>
              </PageHeader>
              <Row className="content">
                <ForwarderDetailsComponent forwarder={forwarder}
                                           onUpdate={() => {}} />
              </Row>
              <Row className="content">
                <Col md={12}>
                  <ForwarderInputProfile forwarder={forwarder}
                                         inputProfileId={forwarder.input_profile_id}
                                         onInputProfileSwitch={_handleInputProfileSwitch(forwarder)} />
                </Col>
              </Row>
            </DocumentTitle>
          );
        }}
      </ForwardersContext.Consumer>
    </SingleForwarderProvider>
  );
};

ForwarderPage.propTypes = {
  params: PropTypes.shape({ forwarderId: PropTypes.string.isRequired }).isRequired,
};

export default withParams(withIsPermitted(ForwarderPage, requiredPermissions));
