import * as React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import { capitalize } from 'lodash';
import styled from 'styled-components';

import connect from 'stores/connect';
import { Alert, Button, Row } from 'components/graylog';
import CustomPropTypes from 'views/components/CustomPropTypes';
import ValueParameter from 'views/logic/parameters/ValueParameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';
import Parameter from 'views/logic/parameters/Parameter';
import { SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';

import ParameterDeclarationForm from './ParameterDeclarationForm';

const StyledAlert = styled(Alert)`
  margin-bottom: 6px;
`;

type Props = {
  existingParameters: ParameterMap,
  undeclaredParameters: Immutable.Set<string>,
  onParameterSave: (newParameters: ParameterMap) => unknown,
};

type DeclarationForm = {
  open: () => void,
};

type WrapperProps = {
  children: React.ReactNode,
};

const wrapperForMode = (mode: QueryEditMode): React.ComponentType<WrapperProps> => {
  switch (mode) {
    case 'query': return ({ children }: WrapperProps) => (
      <Row>
        {children}
      </Row>
    );
    case 'widget': return ({ children }: WrapperProps) => <>{children}</>;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

class ParameterBar extends React.Component<Props> {
  static contextType = QueryEditModeContext;

  private declarationForm: DeclarationForm | undefined | null;

  static propTypes = {
    undeclaredParameters: CustomPropTypes.instanceOf(Immutable.Set).isRequired,
    onParameterSave: PropTypes.func.isRequired,
  };

  handleDeclareParameters = () => {
    if (this.declarationForm) {
      this.declarationForm.open();
    }
  };

  render() {
    const { existingParameters, undeclaredParameters: undeclaredParameterNames, onParameterSave } = this.props;

    if (undeclaredParameterNames.size === 0) {
      return null;
    }

    const undeclaredParameters = Immutable.Map<string, Parameter>(undeclaredParameterNames.map((name: string) => ([
      name,
      ValueParameter.create(name, capitalize(name), '', 'string', undefined, false, ParameterBinding.empty()),
    ])));

    const Wrapper = wrapperForMode(this.context);

    return (
      <Wrapper>
        <StyledAlert bsStyle="danger">
          <h4><strong>Undeclared query parameters:</strong> {undeclaredParameterNames.join(', ')}</h4>
          <br />
          <p>All parameters used in the search query need to be declared before the query can be used:</p>
          <br />
          <Button bsStyle="primary" bsSize="small" onClick={this.handleDeclareParameters}>Declare parameters</Button>
        </StyledAlert>
        <ParameterDeclarationForm ref={(c: DeclarationForm | undefined | null) => { this.declarationForm = c; }}
                                  existingParameters={existingParameters}
                                  parameters={undeclaredParameters}
                                  onSave={onParameterSave} />
      </Wrapper>
    );
  }
}

export default connect(ParameterBar, { existingParameters: SearchParameterStore });
