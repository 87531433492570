import * as React from 'react';
import { DocumentTitle } from 'components/common';
import { Panel, Row, Col } from 'components/graylog';
import styled, { css } from 'styled-components';

import { Warning as LicenseCheckWarning } from 'license/LicenseCheck';

const H2 = styled.h2(({ theme }) => css`
  padding-bottom: ${theme.spacings.sm};
`);

const IlluminateLicense = () => {
  return (
    <DocumentTitle title="Illuminate Invalid License">
      <Row className="content">
        <Col sm={12}>
          <H2>Invalid License for Using Illuminate</H2>
          <Panel bsStyle="warning">
            <LicenseCheckWarning licenseSubject="/license/enterprise/illuminate" featureName="Illuminate" />
          </Panel>
        </Col>
      </Row>
    </DocumentTitle>
  );
};

export default IlluminateLicense;
