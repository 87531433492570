import { SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import Parameter from 'views/logic/parameters/Parameter';
import type { Completer } from 'views/components/searchbar/SearchBarAutocompletions';
import type { CompletionResult, Token } from 'views/components/searchbar/ace-types';

const _parameterResult = (parameter: Parameter, score: number = 1): CompletionResult => {
  const name = `$${parameter.name}$`;
  const description = parameter.description.length > 0 ? ` - ${parameter.description}` : '';

  return {
    name,
    value: name,
    score,
    meta: `parameter${description}`,
  };
};

const _wrapParameter = (name) => `$${name}$`;

const mapParameters = (parameters: ParameterMap): ParameterMap => parameters.mapKeys(_wrapParameter).toMap();

class ParameterCompletion implements Completer {
  parameters: ParameterMap;

  constructor() {
    this.parameters = mapParameters(SearchParameterStore.getInitialState());
    SearchParameterStore.listen((newParameters) => { this.parameters = mapParameters(newParameters); });
  }

  getCompletions = (currentToken: Token | undefined | null, lastToken: Token | undefined | null, prefix: string): Array<CompletionResult> => {
    return this.parameters
      .filter((parameter, name) => name.indexOf(prefix) >= 0)
      .map((parameter) => _parameterResult(parameter))
      .toArray();
  }
}

export default ParameterCompletion;
