import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { Button, ControlLabel } from 'components/graylog';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { Spinner } from 'components/common';

import { PackContext } from './context/PackContext';

type Props = {
  onConfirm: (enabled: boolean) => Promise<void>,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
}

const BulkActionsWrap = styled.div(({ theme }) => css`
  padding: ${theme.spacings.sm} 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  
  div > button {
    margin: 0 ${theme.spacings.xs};
  }
`);

const Checkbox = styled.input(({ theme }) => css`
  &[type="checkbox"] {
    margin-right: ${theme.spacings.xs};
  }
`);

const IlluminateBulkActions = ({ onConfirm, onChange }: Props) => {
  const { packs, selectedPackIds } = useContext(PackContext);
  const selectAllCheckboxRef = useRef<HTMLInputElement>();
  const [showConfirmEnable, setShowConfirmEnable] = useState<boolean>(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedPacksNames = useMemo(() => {
    return packs.map((pack) => {
      return selectedPackIds.includes(pack.pack_id) ? pack.title : null;
    }).filter((item) => item);
  }, [packs, selectedPackIds]);

  const indeterminate = useMemo<boolean>(() => (selectedPackIds.length < packs.length && selectedPackIds.length !== 0), [selectedPackIds.length, packs.length]);
  const checked = useMemo<boolean>(() => (selectedPackIds.length === packs.length && selectedPackIds.length !== 0), [selectedPackIds.length, packs.length]);

  const formattedPackNames = useMemo(() => {
    const packNames = [...selectedPacksNames];
    const lastPack = packNames.pop();

    return packNames.length
      ? `${packNames.join(', ')} & ${lastPack}`
      : lastPack;
  }, [selectedPacksNames]);

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.indeterminate = indeterminate;
      selectAllCheckboxRef.current.checked = checked;
    }
  }, [checked, indeterminate]);

  const toggleConfirmEnable = useCallback(() => {
    setShowConfirmEnable(!showConfirmEnable);
  }, [showConfirmEnable]);

  const toggleConfirmDisable = useCallback(() => {
    setShowConfirmDisable(!showConfirmDisable);
  }, [showConfirmDisable]);

  const dialogToggleConfirm = useMemo(() => (showConfirmEnable ? toggleConfirmEnable : toggleConfirmDisable), [showConfirmEnable, toggleConfirmEnable, toggleConfirmDisable]);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm(showConfirmEnable);
    setIsLoading(false);

    dialogToggleConfirm();
  };

  const actionText = showConfirmEnable ? 'Enabling' : 'Disabling';

  return (
    <>
      <BulkActionsWrap>
        <span>
          <ControlLabel htmlFor="illuminate-bulk">
            <Checkbox type="checkbox"
                      name="illuminate-bulk"
                      id="illuminate-bulk"
                      ref={selectAllCheckboxRef}
                      onChange={onChange} />
            <span>{selectedPacksNames.length} Selected</span>
          </ControlLabel>
        </span>

        <div>
          <Button bsStyle="primary"
                  bsSize="xsmall"
                  onClick={toggleConfirmEnable}
                  disabled={selectedPacksNames.length === 0}>
            Enable Selected
          </Button>

          <Button bsStyle="warning"
                  bsSize="xsmall"
                  onClick={toggleConfirmDisable}
                  disabled={selectedPacksNames.length === 0}>
            Disable Selected
          </Button>
        </div>
      </BulkActionsWrap>

      <ConfirmDialog show={showConfirmEnable || showConfirmDisable}
                     onConfirm={handleConfirm}
                     btnConfirmText={isLoading ? <Spinner text={`${actionText}...`} delay={0} /> : 'Confirm'}
                     btnConfirmDisabled={isLoading}
                     btnCancelDisabled={isLoading}
                     onCancel={dialogToggleConfirm}
                     title={`${actionText} Illuminate Pack`}>
        <>
          <p>You are about to {showConfirmEnable ? 'enable' : 'disable'} <strong>{formattedPackNames}</strong>.</p>
          <p>This could cause potential {showConfirmEnable ? 'creation' : 'loss'} of data and will pause all processing for a few seconds.</p>
          <p>Do you wish to continue?</p>
        </>
      </ConfirmDialog>
    </>
  );
};

export default IlluminateBulkActions;