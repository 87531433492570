// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* Restore bootstrap heading margins because our stylesheet is :shit: */\n._133EbcHtFZ5aeUrQCNTAVb h1, ._133EbcHtFZ5aeUrQCNTAVb h2, ._133EbcHtFZ5aeUrQCNTAVb h3 {\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n._133EbcHtFZ5aeUrQCNTAVb h4, ._133EbcHtFZ5aeUrQCNTAVb h5, ._133EbcHtFZ5aeUrQCNTAVb h6 {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/views/pages/StyleFixups.css"],"names":[],"mappings":"AAAA,uEAAuE;AACvE;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["/* Restore bootstrap heading margins because our stylesheet is :shit: */\n:local(.bootstrapHeading) h1, :local(.bootstrapHeading) h2, :local(.bootstrapHeading) h3 {\n    margin-top: 20px;\n    margin-bottom: 10px;\n}\n\n:local(.bootstrapHeading) h4, :local(.bootstrapHeading) h5, :local(.bootstrapHeading) h6 {\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bootstrapHeading": "_133EbcHtFZ5aeUrQCNTAVb"
};
export default ___CSS_LOADER_EXPORT___;
