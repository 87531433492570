import * as React from 'react';
import { useContext } from 'react';
import * as Immutable from 'immutable';
import { useFormikContext, Field } from 'formik';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Input } from 'components/bootstrap';
import { Alert } from 'components/graylog';
import { validateField } from 'util/FormsUtils';
import BackendWizardContext from 'components/authentication/directoryServices/BackendWizard/BackendWizardContext';

import MatchingGroupsContext from './MatchingGroupsContext';

import Group from '../../logic/directoryServices/Group';

const Groups = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  padding-top: 5px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`;

const SelectedGroup = styled.button(({ theme }) => `
  display: flex;
  font-size: ${theme.fonts.size.small};
  color: ${theme.colors.gray[20]};
  padding: 0;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid ${theme.colors.variant.lighter.info};
  border-radius: 2px;
  background-color: ${theme.colors.variant.lightest.info};
`);

const SelectedGroupTitle = styled.div`
  padding: 2px 5px;
`;

const SelectedGroupRemove = styled.div(({ theme }) => `
  border-left: 1px solid ${theme.colors.variant.lighter.info};
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
`);

const AllGroupsInfo = () => (
  <Alert bsStyle="info">
    <Icon name="info-circle" />{' '}With the selection type &quot;All&quot;, all matching groups will be synchronized. We recommend you to load matching groups to test the configuration above.
  </Alert>
);

const LoadingGroupsRequired = ({ selectionType }: { selectionType: string}) => (
  <Alert bsStyle="warning">
    <Icon name="exclamation-triangle" />{' '}Please load all matching groups to test your configuration and to select all groups you want to {selectionType}.
  </Alert>
);

const GroupSelectionRequired = ({ selectionType }: { selectionType: string }) => (
  <Alert bsStyle="warning">
    <Icon name="exclamation-triangle" />{' '}No groups select, use the list of matched groups to select every group you want to {selectionType}.
  </Alert>
);

type SelectedGroupsProps = {
  matchingGroups: Immutable.List<Group>,
  onGroupSelect: (groupId: string) => void,
  selectedGroupsIds: Immutable.Set<string>,
};

const SelectedGroups = ({ matchingGroups = Immutable.List(), selectedGroupsIds, onGroupSelect }: SelectedGroupsProps) => (
  <Groups>
    {selectedGroupsIds.map((groupId) => {
      const group = matchingGroups.find((g) => g.id === groupId);

      return (
        <SelectedGroup type="button" onClick={() => onGroupSelect(groupId)} key={groupId}>
          <SelectedGroupTitle>{group?.title || <>{groupId} <b>(not found)</b></>}</SelectedGroupTitle>
          <SelectedGroupRemove>×</SelectedGroupRemove>
        </SelectedGroup>
      );
    })}
  </Groups>
);

const _getLabel = (selectionType: string) => {
  switch (selectionType) {
    case 'include':
      return 'Included Groups';
    case 'exclude':
      return 'Excluded Groups';
    case 'all':
    default:
      return 'Selected Groups';
  }
};

type Props = {
  onGroupSelect: (groupId: string) => void,
  validation: {
    teamSelection?: { required?: boolean },
  },
};

const SelectedGroupsOverview = ({ onGroupSelect, validation }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setStepsState, ...stepsState } = useContext(BackendWizardContext);
  const { backendValidationErrors } = stepsState;
  const { values: { teamSelectionType } } = useFormikContext();
  const { result: loadGroupsResult } = useContext(MatchingGroupsContext);

  return (
    <Field name="teamSelection" validate={validateField(validation.teamSelection)}>
      {({ field: { value: teamSelection }, meta: { error } }) => (
        <Input error={error ?? backendValidationErrors?.teamSelection}
               id="team-select"
               label={_getLabel(teamSelectionType)}
               labelClassName="col-sm-3"
               wrapperClassName="col-sm-9">
          <>
            {teamSelectionType === 'all' && (
              <AllGroupsInfo />
            )}
            {teamSelectionType !== 'all' && !loadGroupsResult && (
              <LoadingGroupsRequired selectionType={teamSelectionType} />
            )}
            {teamSelectionType !== 'all' && loadGroupsResult && teamSelection?.size === 0 && (
              <GroupSelectionRequired selectionType={teamSelectionType} />
            )}
            {loadGroupsResult && teamSelection?.size >= 1 && (
              <SelectedGroups matchingGroups={loadGroupsResult?.list}
                              onGroupSelect={onGroupSelect}
                              selectedGroupsIds={teamSelection} />
            )}
          </>
        </Input>
      )}
    </Field>
  );
};

export default SelectedGroupsOverview;
