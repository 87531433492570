import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Col, OverlayTrigger, Row } from 'components/graylog';
import { EntityList, Icon, PaginatedList, SearchForm, Spinner } from 'components/common';
import { DEFAULT_PAGINATION } from 'stores/PaginationTypes';

import Report from './Report';
import ReportQueryHelper from './ReportQueryHelper';

import ReportsActions from '../ReportsActions';

type Props = {
  licenseStatus: {
    missing: boolean,
    status: {
      valid: boolean,
    };
  },
};

type ReportType = {
  id: string,
  title: string,
  subtitle: string,
  logo: string,
  description: string,
  widgets: Array<unknown>,
  positions: Array<unknown>,
  scheduling: unknown,
};

type PaginatedReports = {
  reports: Array<ReportType>
  count: number,
  total: number,
  page: number,
  perPage: number,
  query: string,
};

const _loadData = (pagination, setReports) => {
  ReportsActions.listPage(pagination)
    .then((reports) => {
      setReports(reports);
    });
};

const _updateListOnReportDelete = (perPage, query, setPagination) => ReportsActions.delete.completed.listen(() => setPagination({ page: DEFAULT_PAGINATION.page, perPage, query }));

const queryHelperComponent = (
  <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={<ReportQueryHelper />}>
    <Button bsStyle="link" className="archive-search-help-button">
      <Icon name="question-circle" fixedWidth />
    </Button>
  </OverlayTrigger>
);

const ReportList = ({ licenseStatus }: Props) => {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [paginatedReports, setPaginatedReports] = useState<PaginatedReports | undefined>();
  const { query, perPage, page } = pagination;

  useEffect(() => _loadData(pagination, setPaginatedReports), [pagination]);
  useEffect(() => _updateListOnReportDelete(perPage, query, setPagination), [perPage, query]);

  const _onPageChange = (newPage, newPerPage) => setPagination({ ...pagination, perPage: newPerPage, page: newPage });
  const _onSearch = (newQuery) => setPagination({ ...pagination, query: newQuery, page: DEFAULT_PAGINATION.page });
  const _onReset = () => _onSearch('');

  const _formatReport = (report) => {
    return <Report key={report.id} report={report} licenseStatus={licenseStatus} />;
  };

  if (!paginatedReports) {
    return <div><Spinner delay={500} /></div>;
  }

  return (
    <div>
      <Row className="row-sm">
        <Col md={8}>
          <SearchForm onSearch={_onSearch}
                      queryHelpComponent={queryHelperComponent}
                      onReset={_onReset} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <PaginatedList onChange={_onPageChange} totalItems={paginatedReports.total} activePage={page}>
            <br />
            <br />
            <EntityList noItemsText="There are no reports to display, adjust your filter or click on 'Create report' to get started."
                        items={paginatedReports.reports.map((report) => _formatReport(report))} />
          </PaginatedList>
        </Col>
      </Row>
    </div>
  );
};

ReportList.propTypes = {
  licenseStatus: PropTypes.object.isRequired,
};

export default ReportList;
