import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import 'twix';
import naturalSort from 'javascript-natural-sort';
import styled from 'styled-components';

import { Button, Col, Row, Table } from 'components/graylog';
import { Icon, Timestamp, Spinner, IfPermitted, PaginatedList } from 'components/common';
import NumberUtils from 'util/NumberUtils';
import ArchiveActions from 'archive/ArchiveActions';
import CombinedProvider from 'injection/CombinedProvider';
import { ArchivePropType, ArchiveContextPropType, BackendContextPropType } from 'archive/propTypes';

import { StyledDescriptionWrapper } from './StyledCatalogComponents';

import type { Archive, ArchiveContext, BackendContext, DiskState } from '../types';

const { IndicesActions } = CombinedProvider.get('Indices');

const StyledTbody = styled.tbody<{ showDetails: boolean }>(({ showDetails }) => `
  ${showDetails ? 'border-left: 4px solid #2980b9' : ''}
`);

const StyledHr = styled.hr`
  margin-bottom: 5px;
  margin-top: 10px;
`;

const StyledP = styled.p`
  float: left;
`;

type StreamHistogramsType = {
  streamId: string,
  name: string,
  count: number,
};

type Props = {
  archive: Archive,
  context: ArchiveContext,
  backendContext: BackendContext,
};

type State = {
  showDetails: boolean,
  streamHistograms: Array<StreamHistogramsType>,
  currentStreamHistogramPage: Array<StreamHistogramsType>,
  streamHistogramPage: number,
  streamHistogramPageSize: number,
  diskState: DiskState,
};

class ArchiveCatalogTableEntry extends React.Component<Props, State> {
  static propTypes = {
    archive: ArchivePropType.isRequired,
    context: ArchiveContextPropType.isRequired,
    backendContext: BackendContextPropType.isRequired,
  };

  DEFAULT_PAGE_SIZE = 5;

  constructor(props: Props) {
    super(props);

    this.state = {
      diskState: { loading: true, available: false },
      showDetails: false,
      streamHistograms: [],
      currentStreamHistogramPage: [],
      streamHistogramPage: 1,
      streamHistogramPageSize: this.DEFAULT_PAGE_SIZE,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { archive } = this.props;
    const { streamHistogramPage, streamHistogramPageSize } = this.state;
    const streamHistograms = this._calcStreamHistograms(archive);

    this.setState({
      streamHistograms: streamHistograms,
      currentStreamHistogramPage: this._getHistogramPageContent(streamHistogramPage, streamHistogramPageSize),
    });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { streamHistogramPage, streamHistogramPageSize } = this.state;
    const streamHistograms = this._calcStreamHistograms(newProps.archive);

    this.setState({
      streamHistograms: streamHistograms,
      currentStreamHistogramPage: this._getHistogramPageContent(streamHistogramPage, streamHistogramPageSize),
    });
  }

  toggleDetails = (event: React.MouseEvent<HTMLTableRowElement>) => {
    event.preventDefault();
    const { showDetails } = this.state;
    const { archive } = this.props;
    const expanding = !showDetails;

    if (expanding) {
      // check the availability of the archive on disk
      ArchiveActions.availability(archive.id).then((state) => {
        this.setState({ diskState: { ...state, loading: false } });
      });
    }

    this.setState({ showDetails: expanding });
  };

  _onRestoreIndex = (archive: Archive) => {
    return () => {
      ArchiveActions.restoreArchive(archive.backend_id, archive.archive_id);
    };
  };

  _deleteIndex = (indexName: string) => {
    IndicesActions.delete(indexName);
  };

  _onDeleteArchive = (archive: Archive) => {
    return () => {
      const { context } = this.props;

      // eslint-disable-next-line no-alert
      if (window.confirm(`Are you sure you want to delete archive "${archive.archive_id}" for index "${archive.index_name}"?
 This will also delete the restored indices for this archive if it exist.`)) {
        ArchiveActions.deleteArchive(archive.backend_id, archive.archive_id).then(() => {
          if (context.restored) {
            this._deleteIndex(context.restored_index_name);
          }
        });
      }
    };
  };

  _onDeleteIndex = (indexName: string) => {
    return () => {
      // eslint-disable-next-line no-alert
      if (window.confirm(`Are you sure you want to delete index ${indexName}?`)) {
        this._deleteIndex(indexName);
      }
    };
  };

  _getStreamName = (streamId: string) => {
    const { archive } = this.props;

    if (!archive.id_mappings || !archive.id_mappings.streams) {
      return streamId;
    }

    return archive.id_mappings.streams[streamId] || streamId;
  };

  _onPageChange = (newPage: number, pageSize: number) => {
    const { streamHistograms } = this.state;

    if (streamHistograms.length > 0) {
      const newPageContent = this._getHistogramPageContent(newPage, pageSize);

      this.setState({
        currentStreamHistogramPage: newPageContent,
        streamHistogramPage: newPage,
        streamHistogramPageSize: pageSize,
      });
    }
  };

  _getHistogramPageContent = (page: number, size: number): Array<StreamHistogramsType> => {
    const { streamHistograms } = this.state;

    return streamHistograms.slice((page - 1) * size, page * size);
  };

  _calcStreamHistograms = (archive: Archive): Array<StreamHistogramsType> => {
    const streams = {};

    Object.keys(archive.stream_histogram).forEach((date) => {
      Object.keys(archive.stream_histogram[date]).forEach((streamId) => {
        if (!streams[streamId]) {
          streams[streamId] = 0;
        }

        streams[streamId] += archive.stream_histogram[date][streamId];
      });
    });

    return Object.keys(streams).map((streamId: string) => ({ streamId: streamId, name: this._getStreamName(streamId), count: streams[streamId] }))
      .sort((a, b) => naturalSort(a.name, b.name));
  };

  _renderStreamsDetails = () => {
    const { streamHistograms, currentStreamHistogramPage } = this.state;

    if (streamHistograms.length < 1) {
      return null;
    }

    const streamRows = currentStreamHistogramPage
      .map((histogramEntry) => {
        return (
          <tr key={histogramEntry.streamId}>
            <td>{histogramEntry.name}</td>
            <td>{NumberUtils.formatNumber(histogramEntry.count)}</td>
          </tr>
        );
      });

    return (
      <StyledDescriptionWrapper marginLeft={150}>
        <div className="table-responsive">
          <PaginatedList onChange={this._onPageChange}
                         totalItems={streamHistograms.length}
                         pageSize={this.DEFAULT_PAGE_SIZE}
                         pageSizes={[5, 10, 20, 50]}>

            <StyledP>Number of archived messages per stream. <strong>Note:</strong> Messages can be in multiple streams.</StyledP>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th>Stream</th>
                  <th>Message count</th>
                </tr>
              </thead>
              <tbody>
                {streamRows}
              </tbody>
            </Table>
          </PaginatedList>
        </div>
      </StyledDescriptionWrapper>
    );
  };

  _renderDetails = (archive: Archive, context: ArchiveContext, backendContext: BackendContext) => {
    const segmentSize = archive.segments.reduce((sum, segment) => sum + segment.size, 0);
    const segmentRawSize = archive.segments.reduce((sum, segment) => sum + segment.raw_size, 0);
    const compressionType = archive.segments.map((segment) => segment.compression_type)[0];
    const { diskState } = this.state;
    let restoreButton;

    if (context.restored) {
      restoreButton = (
        <>
          <Button bsStyle="success" bsSize="xs" disabled>Restored as {context.restored_index_name}</Button>
          &nbsp;
          <Button bsStyle="danger" bsSize="xs" onClick={this._onDeleteIndex(context.restored_index_name)}>Delete restored index</Button>
        </>
      );
    } else {
      restoreButton = (
        <IfPermitted permissions="archive:restore">
          <Button bsStyle="success" bsSize="xs" onClick={this._onRestoreIndex(archive)}>Restore index</Button>
        </IfPermitted>
      );
    }

    let rawSizeInfo;

    if (segmentRawSize > 0) {
      rawSizeInfo = ` / ${NumberUtils.formatBytes(segmentRawSize)} uncompressed`;
    }

    let archiveFileCheck;

    if (diskState.loading) {
      archiveFileCheck = <Spinner text="Checking archive availability" />;
    } else if (diskState.available) {
      archiveFileCheck = (<span><Icon name="check" className="text-success" />&nbsp;Archive available</span>);
    } else {
      archiveFileCheck = (<span><Icon name="times" className="text-danger" />&nbsp;Archive not available</span>);
    }

    const archiveDuration = moment.duration(archive.creation_duration, 'milliseconds').format('h [h] m [min] s [sec] S [ms]');

    return (
      <span>
        <Row>
          <Col md={6}>
            <StyledDescriptionWrapper marginLeft={150}>
              <dl>
                <dt>Index name:</dt>
                <dd>{archive.index_name}</dd>
                <dt>Backend:</dt>
                <dd>{backendContext.title}</dd>
                <dt>Created:</dt>
                <dd><Timestamp dateTime={archive.created_at} /> (took {archiveDuration})</dd>
                <dt>Message count:</dt>
                <dd>{NumberUtils.formatNumber(archive.document_count)}</dd>
                <dt>Earliest message:</dt>
                <dd><Timestamp dateTime={archive.timestamp_min} /></dd>
                <dt>Latest message:</dt>
                <dd><Timestamp dateTime={archive.timestamp_max} /></dd>
                <dt>Segment count:</dt>
                <dd>{archive.segments.length}</dd>
                <dt>Segments size:</dt>
                <dd>{NumberUtils.formatBytes(segmentSize)} (compressed with {compressionType}{rawSizeInfo})</dd>
                <dt>Segment directory:</dt>
                <dd>{context.path}</dd>
                <dt>Archive availability</dt>
                <dd>{archiveFileCheck}</dd>
                {
                  context.restored
                  && (
                    <span>
                      <dt>Restored index:</dt>
                      <dd>{context.restored_index_name}</dd>
                    </span>
                  )
                }
              </dl>
            </StyledDescriptionWrapper>
          </Col>
          <Col md={6}>
            {this._renderStreamsDetails()}
          </Col>
        </Row>
        <IfPermitted permissions={['archive:create', 'archive:delete']}>
          <StyledHr />
          {restoreButton}
          <IfPermitted permissions="archive:delete">
            &nbsp;
            <Button bsStyle="danger" bsSize="xs" onClick={this._onDeleteArchive(archive)}>Delete archive</Button>{' '}
          </IfPermitted>
        </IfPermitted>
      </span>
    );
  };

  _renderStreamNames = () => {
    const { archive } = this.props;

    if (!(archive.stream_names)) {
      return <em>No streams</em>;
    }

    return archive.stream_names
      .sort((a, b) => naturalSort(a, b))
      .join(', ');
  };

  render() {
    const { archive, backendContext, context } = this.props;
    const { showDetails } = this.state;
    const contentRange = moment(archive.timestamp_min).twix(archive.timestamp_max);

    return (
      <StyledTbody className="archive-catalog-entry" showDetails={showDetails}>
        <tr onClick={this.toggleDetails} className="toggle-details">
          <td>
            <strong>{archive.index_name}</strong>
          </td>
          <td>{backendContext.title}</td>
          <td><Timestamp dateTime={archive.created_at} relative /></td>
          <td>{contentRange.format({ hourFormat: 'H' })}</td>
          <td>{NumberUtils.formatNumber(archive.document_count)} msgs ({contentRange.humanizeLength()})</td>
          <td className="stretch">
            {this._renderStreamNames()}
          </td>
          <td className="restored">
            {context.restored ? <Icon name="check" /> : null}
          </td>
        </tr>
        {showDetails
        && (
          <tr className="archive-catalog-entries-details">
            <td colSpan={7}>
              {this._renderDetails(archive, context, backendContext)}
            </td>
          </tr>
        )}
      </StyledTbody>
    );
  }
}

export default ArchiveCatalogTableEntry;
