import React, { useState } from 'react';

import Parameter from 'views/logic/parameters/Parameter';

import MissingParametersForWidgetPreview from './MissingParametersForWidgetPreview';
import ReportingWidget from './ReportingWidget';

type Props = {
  widget: {
    parameters: Array<Parameter>,
  },
  searchId: string,
};

const ReportingWidgetPreview = (props: Props) => {
  const { widget: { parameters }, searchId } = props;
  const [parameterValues, setParameterValues] = useState({});
  const requiredParameters = parameters
    .filter((p) => (!p.optional && !p.defaultValue && p.needsBinding))
    .filter((p) => !parameterValues[p.name]);
  const parameterSearchIds = parameters.reduce((searchIds, parameter) => {
    return { ...searchIds, [parameter.name]: searchId };
  }, {});

  if (requiredParameters && requiredParameters.length > 0) {
    return (
      <MissingParametersForWidgetPreview parameters={parameters}
                                         parameterValues={parameterValues}
                                         onSubmit={setParameterValues}
                                         parameterSearchIds={parameterSearchIds} />
    );
  }

  return <ReportingWidget {...props} parameterValues={parameterValues} />;
};

ReportingWidgetPreview.propTypes = {};

export default ReportingWidgetPreview;
