import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import { Icon } from 'components/common';
import { UsersActions } from 'stores/users/UsersStore';
import User from 'logic/users/User';
import { Col, Panel, PanelGroup, Row } from 'components/graylog';
import AppConfig from 'util/AppConfig';
import CombinedProvider from 'injection/CombinedProvider';
import { ForwarderPropType, Forwarder } from 'forwarder/Types';
import { StyledPanel } from 'forwarder/CommonStyledComponents';
import { Input } from 'components/messageloaders/Types';
import { useConfiguration } from 'forwarder/wizard/select-forwarder-step/useConfiguration';

import LinkToAPIToken from './LinkToAPIToken';
import InstallForwarderStep from './start-new-forwarder-steps/InstallForwarderStep';
import CreateTokenStep from './start-new-forwarder-steps/CreateTokenStep';
import ConfigureNewForwarderStep from './start-new-forwarder-steps/ConfigureNewForwarderStep';
import SelectNewForwarderStep from './start-new-forwarder-steps/SelectNewForwarderStep';

import { StyledTitle } from '../StyledWizardComponents';

const { InputsActions } = CombinedProvider.get('Inputs');

const StyledPanelGroup = styled(PanelGroup)`
  &.panel-group > .panel {
    margin-top: 0;
    border-color: ${(props) => props.theme.colors.input.border};
    background-color: ${(props) => props.theme.colors.global.contentBackground};

    .panel-heading {
      background-color: ${(props) => props.theme.colors.table.backgroundAlt};
    }

    &:not(:first-child) {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const StyledUL = styled.ul`
  list-style-type: disc;
  margin: 10px 0;
`;

const StyledHelpPanel = styled(StyledPanel)`
  margin-top: 30px;
`;

const FORWARDER_USER = 'graylog-forwarder';
const CLOUD_FORWARDER_CONFIGURATION_PORT = '13301';
const CLOUD_FORWARDER_TRANSMISSION_PORT = '13302';

type FirewallHelpMessageProps = {
  configuration: { [key:string]: any };
};

const FirewallHelpMessage = ({ configuration }: FirewallHelpMessageProps) => {
  if (AppConfig.isCloud()) {
    return (
      <li>
        Ensure your Firewall allows the connection to Graylog on
        ports <code>{CLOUD_FORWARDER_CONFIGURATION_PORT}</code>{' '}
        and <code>{CLOUD_FORWARDER_TRANSMISSION_PORT}</code>.
      </li>
    );
  }

  if (configuration.forwarder_configuration_port && configuration.forwarder_message_transmission_port) {
    return (
      <li>
        Ensure your Firewall allows the connection to Graylog on
        ports <code>{configuration.forwarder_configuration_port}</code>{' '}
        and <code>{configuration.forwarder_message_transmission_port}</code>.
      </li>
    );
  }

  return (
    <li>Ensure your Firewall allows the connection to Graylog on configured ports for the Forwarder Input.</li>
  );
};

type Props = {
  forwarders: Array<Forwarder>,
  onForwarderSelect: () => void,
};

const StartNewForwarder = ({ forwarders, onForwarderSelect }: Props) => {
  const [activeStep, setActiveStep] = useState<'installForwarder' | 'createToken' | 'configureForwarder' | 'selectForwarder'>('installForwarder');
  const [forwarderUser, setForwarderUser] = useState<User>();
  const [forwarderInputs, setForwarderInputs] = useState<Array<Input>>();
  const [configuration] = useConfiguration(forwarderInputs?.[0]);
  const [token, setToken] = useState();

  useEffect(() => {
    UsersActions.loadByUsername(encodeURIComponent(FORWARDER_USER))
      .then((user) => setForwarderUser(user));

    if (!AppConfig.isCloud()) {
      InputsActions.list().then(({ inputs }) => {
        const sortedForwarderInputs = sortBy(
          inputs.filter((input) => input.type === 'org.graylog.plugins.forwarder.input.ForwarderServiceInput'),
          'created_at',
        );
        setForwarderInputs(sortedForwarderInputs);
      });
    }
  }, []);

  const handleTokenCreation = (nextToken) => {
    setToken(nextToken);
    setActiveStep('configureForwarder');
  };

  return (
    <>
      <Row>
        <Col md={6}>
          <StyledTitle>Start new Forwarder</StyledTitle>
          <p>Follow these steps to connect a new Forwarder to Graylog.</p>
          <StyledPanelGroup accordion id="startForwarder" activeKey={activeStep} onSelect={setActiveStep}>
            <InstallForwarderStep eventKey="installForwarder"
                                  onStepComplete={() => setActiveStep('createToken')} />
            <CreateTokenStep eventKey="createToken"
                             user={forwarderUser}
                             token={token}
                             onTokenCreate={handleTokenCreation}
                             onStepComplete={() => setActiveStep('configureForwarder')} />
            <ConfigureNewForwarderStep eventKey="configureForwarder"
                                       token={token}
                                       forwarderInputs={forwarderInputs}
                                       onStepComplete={() => setActiveStep('selectForwarder')} />
            <SelectNewForwarderStep eventKey="selectForwarder"
                                    forwarders={forwarders}
                                    onForwarderSelect={onForwarderSelect} />
          </StyledPanelGroup>
        </Col>

        <Col md={5} mdOffset={1}>
          <StyledHelpPanel bsStyle="info">
            <Panel.Heading>
              <Panel.Title componentClass="h3"><Icon name="info-circle" /> Forwarder connection</Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <p>
                Having trouble configuring and connecting to a Graylog Forwarder? Here are some things you
                can check:
              </p>
              <StyledUL>
                <li>Double-check the Graylog URL set in the Forwarder configuration</li>
                <li>
                  Make sure that the{' '}
                  <LinkToAPIToken user={forwarderUser}>
                    API Token set in the Forwarder configuration belongs to{' '}
                    <code>Forwarder System User (built-in)</code>
                  </LinkToAPIToken>
                  {' '}(or a different user with the <code>Forwarder System (Internal)</code> role)
                </li>
                <FirewallHelpMessage configuration={configuration} />
              </StyledUL>
              <p>
                Still having trouble?{' '}
                <a href="https://www.graylog.org/community-support/" target="_blank" rel="noopener noreferrer">
                  Contact us
                </a>.
              </p>
            </Panel.Body>
          </StyledHelpPanel>
        </Col>
      </Row>
    </>
  );
};

StartNewForwarder.propTypes = {
  forwarders: PropTypes.arrayOf(ForwarderPropType),
  onForwarderSelect: PropTypes.func.isRequired,
};

StartNewForwarder.defaultProps = {
  forwarders: [],
};

export default StartNewForwarder;
