import LicensesPage from 'license/LicensesPage';
import LicenseNotificationContainer from 'license/notifications/LicenseNotificationContainer';

import LicenseCheckProvider from './LicenseCheckProvider';

export default {
  routes: [
    { path: '/system/licenses', component: LicensesPage },
  ],
  globalNotifications: [
    {
      key: 'org.graylog.plugins.license.LicenseNotificationContainer',
      component: LicenseNotificationContainer,
    },
  ],
  globalContextProviders: [
    LicenseCheckProvider,
  ],
};
