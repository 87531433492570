import ReportConfigurationPage from 'report/report-configuration-page/ReportConfigurationPage';
import ReportContentsPage from 'report/report-contents-page/ReportContentsPage';
import ReportHistoryPage from 'report/report-history-page/ReportHistoryPage';
import ReportRenderPage from 'report/report-render-page/ReportRenderPage';
import ReportsPage from 'report/reports-page/ReportsPage';
import EditReportContentsPage from 'report/report-contents-page/EditReportContentsPage';

export default {
  routes: [
    { path: '/reports', component: ReportsPage, permissions: 'inputs:create' },
    { path: '/reports/new', component: ReportContentsPage, permissions: 'inputs:create' },
    { path: '/reports/:reportId/configuration', component: ReportConfigurationPage, permissions: 'inputs:create' },
    { path: '/reports/:reportId/contents', component: EditReportContentsPage, permissions: 'inputs:create' },
    { path: '/reports/:reportId/history', component: ReportHistoryPage, permissions: 'inputs:create' },
    { path: '/reports/:reportId/render', component: ReportRenderPage, permissions: 'inputs:create', parentComponent: null },
  ],
};
