import CombinedProvider from 'injection/CombinedProvider';

const { NodesActions, NodesStore } = CombinedProvider.get('Nodes');

const isLocalNode = async (nodeId: string) => {
  const { nodes } = NodesStore.getInitialState() ?? {};

  if (nodes) {
    return nodes[nodeId] !== undefined;
  }

  const response = await NodesActions.list();

  const requestedNode = (response?.nodes ?? [])
    .filter((node) => node?.node_id === nodeId);

  return requestedNode.length > 0;
};

export default isLocalNode;
