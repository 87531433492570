import Reflux from 'reflux';
import { singletonActions } from 'views/logic/singleton';
import { RefluxActions } from 'stores/StoreTypes';

import { ActionsType } from 'illuminate/types';

const IlluminateActions: RefluxActions<ActionsType> = singletonActions('Illuminate',
  () => Reflux.createActions({
    listPacks: { asyncResult: true },
    updatePacks: { asyncResult: true },
    listBundles: { asyncResult: true },
    updateBundle: { asyncResult: true },
    deleteBundle: { asyncResult: true },
    uploadBundle: { asyncResult: true },
  }));

export default IlluminateActions;
