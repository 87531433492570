import React from 'react';
import createReactClass from 'create-react-class';
import Reflux from 'reflux';
import { Link } from 'components/graylog/router';
import { Button, Col, Row } from 'components/graylog';
import DocumentationLink from 'components/support/DocumentationLink';
import { DataTable, DocumentTitle, PageHeader, Spinner, Timestamp } from 'components/common';
import StoreProvider from 'injection/StoreProvider';
import ActionsProvider from 'injection/ActionsProvider';
import Routes from 'routing/Routes';

import LicensesStore from 'license/LicensesStore';
import LicensesActions from 'license/LicensesActions';
import ImportLicenseModal from 'license/ImportLicenseModal';
import LicenseDetails from 'license/LicenseDetails';
import LicenseViolations from 'license/LicenseViolations';

import { LICENSE_TYPES } from './constants';

const NodesStore = StoreProvider.getStore('Nodes');
const NodesActions = ActionsProvider.getActions('Nodes');

const LicensesPage = createReactClass({
  displayName: 'LicensesPage',
  mixins: [Reflux.connect(LicensesStore), Reflux.connect(NodesStore)],

  componentDidMount() {
    LicensesActions.listStatus();
    NodesActions.list();
  },

  _formatSubject(license) {
    return LICENSE_TYPES[license.subject];
  },

  _verifyLicense(token) {
    LicensesActions.verify(token);
  },

  _saveLicense(token, cb) {
    LicensesActions.save(token).then(() => {
      LicensesActions.listStatus();
      cb();
    });
  },

  _deleteLicense(license) {
    return () => {
      // eslint-disable-next-line no-undef,no-alert
      if (confirm(`Do you really want to remove the license for "${this._formatSubject(license)}"? This action cannot be undone.`)) {
        LicensesActions.remove(license.id).then(() => LicensesActions.listStatus());
      }
    };
  },

  _clusterId() {
    if (this.state.nodes) {
      return Object.keys(this.state.nodes).map((id) => this.state.nodes[id]).map((node) => node.cluster_id)[0].toUpperCase();
    }

    return undefined;
  },

  _headerCellFormatter(header) {
    return <th>{header}</th>;
  },

  _licenseInfoFormatter(licenseStatus, i) {
    const { license } = licenseStatus;
    const violations = {
      cluster_not_covered: licenseStatus.cluster_not_covered,
      nodes_exceeded: licenseStatus.nodes_exceeded,
      traffic_exceeded: licenseStatus.traffic_exceeded,
      remote_checks_failed: licenseStatus.remote_checks_failed,
      expired: licenseStatus.expired,
      violated: licenseStatus.violated,
    };

    return (
      <tr key={`${license.id}-${i}`} className={violations.violated || violations.expired ? 'danger' : null}>
        <td className="limited">{this._formatSubject(license)}</td>
        <td className="limited">{license.audience.company}</td>
        <td className="limited">{`${license.audience.name} <${license.audience.email}>`}</td>
        <td className="limited"><Timestamp dateTime={license.expiration_date} relative /></td>
        <td className="limited">{license.id}</td>
        <td className="limited" style={{ width: '20%' }}>
          <LicenseDetails trial={license.trial}
                          version={license.version}
                          enterprise={license.enterprise}
                          violations={violations} />
        </td>
        <td className="limited" style={{ width: '20%' }}>
          <LicenseViolations trial={license.trial}
                             version={license.version}
                             violations={violations} />
        </td>
        <td className="limited">
          <Button bsStyle="primary" bsSize="xsmall" onClick={this._deleteLicense(license)}>Delete</Button>
        </td>
      </tr>
    );
  },

  _renderLicenses() {
    const headers = ['License Type', 'Licensed to', 'Contact', 'Expiration date', 'Id', 'Details', 'State', 'Actions'];

    return (
      <DataTable id="license-list"
                 rowClassName="row-sm"
                 className="table-hover table-condensed table-striped"
                 headers={headers}
                 headerCellFormatter={this._headerCellFormatter}
                 sortByKey="expires_in"
                 rows={this.state.licensesStatus}
                 dataRowFormatter={this._licenseInfoFormatter}
                 filterLabel="Filter"
                 filterKeys={[]}
                 noDataText="There are no licenses installed in this cluster." />
    );
  },

  render() {
    const content = this.state.licensesStatus && this.state.nodes ? this._renderLicenses() : <Spinner />;
    const clusterId = this._clusterId();
    let clusterIdText = null;

    if (clusterId) {
      clusterIdText = <p>This Graylog cluster ID is {clusterId}</p>;
    }

    return (
      <DocumentTitle title="Graylog license management">
        <span>
          <PageHeader title="Graylog License Management">
            <span>
              Manage your Graylog Enterprise licenses. You can get a new license from the{' '}
              <Link to={Routes.SYSTEM.ENTERPRISE}>Enterprise page</Link>.
            </span>
            <span>
              Read more about licenses in the <DocumentationLink page="enterprise/licenses.html" text="documentation" />.
            </span>
            <span>
              <ImportLicenseModal key="new-license"
                                  saveLicense={this._saveLicense}
                                  verifyLicense={this._verifyLicense}
                                  errorMessage={this.state.errorMessage}
                                  verifiedLicense={this.state.verifiedLicense} />
            </span>
          </PageHeader>

          <Row className="content">
            <Col md={12}>
              <h2 style={{ marginBottom: 5 }}>Installed licenses</h2>
              {clusterIdText}
              {content}
            </Col>
          </Row>
        </span>
      </DocumentTitle>
    );
  },
});

export default LicensesPage;
