import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row, Col, MenuItem } from 'components/graylog';
import BootstrapModalForm from 'components/bootstrap/BootstrapModalForm';
import { Spinner } from 'components/common';
import ArchiveActions from 'archive/ArchiveActions';

const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 400px;
`;

type Props = {
  query: string,
};

const ArchiveCatalogExportModal = ({ query }: Props) => {
  const [filenames, setFilenames] = useState<Array<string>>([]);
  const modal = useRef<BootstrapModalForm>();

  const open = useCallback(() => { modal.current?.open(); }, []);

  const close = useCallback(() => { modal.current?.close(); }, []);

  const _onModalOpen = () => {
    ArchiveActions.exportFilenames(query)
      .then((response) => setFilenames(response.filenames));
  };

  const _onSubmit = (e) => {
    e.preventDefault();
    close();
  };

  const _isLoading = () => {
    return !filenames;
  };

  const _modalContent = () => {
    if (_isLoading()) {
      return <Spinner text="Loading filenames..." />;
    }

    return (
      <Row>
        <Col md={12}>
          <StyledTextArea value={filenames.join('\n')}
                          readOnly />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <MenuItem onClick={open}>Filenames</MenuItem>

      <BootstrapModalForm ref={modal}
                          title="Catalog Filenames Export"
                          onModalOpen={_onModalOpen}
                          onSubmitForm={_onSubmit}
                          submitButtonText="Close">
        {_modalContent()}
      </BootstrapModalForm>
    </>
  );
};

ArchiveCatalogExportModal.propTypes = {
  query: PropTypes.string.isRequired,
};

export default ArchiveCatalogExportModal;
