// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xipV2JTI5APv5Xr-rv2Le {\n    padding-left: 0;\n    margin-bottom: 20px;\n}\n\n._3xipV2JTI5APv5Xr-rv2Le ._3xipV2JTI5APv5Xr-rv2Le {\n    margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./../../graylog2-server/graylog2-web-interface/src/components/common/ExpandableList.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[":local(.list) {\n    padding-left: 0;\n    margin-bottom: 20px;\n}\n\n:local(.list) :local(.list) {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "_3xipV2JTI5APv5Xr-rv2Le"
};
export default ___CSS_LOADER_EXPORT___;
