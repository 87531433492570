import * as React from 'react';

import { BackendMessage } from 'views/components/messagelist/Types';
import { singleton } from 'views/logic/singleton';

export interface MessageDetailsContextValue {
  activeMessageDetails: BackendMessage,
  setActiveMessageDetailsId: (messageId: BackendMessage['message']['_id']) => void,
  selectNextMessage: () => void | undefined,
  selectPrevMessage: () => void | undefined,
}

const MessageDetailsContext = React.createContext<MessageDetailsContextValue | undefined>(undefined);

export default singleton('contexts.enterprise.LogViewMessageDetailsContext', () => MessageDetailsContext);
