import Reflux from 'reflux';
import { Store } from 'stores/StoreTypes';
import fetch from 'logic/rest/FetchProvider';
import { singletonStore } from 'views/logic/singleton';
import { qualifyUrl } from 'util/URLUtils';
import StoreProvider from 'injection/StoreProvider';
import UserNotification from 'util/UserNotification';

import { Bundle, Bundles, Pack, Packs } from 'illuminate/types';

import IlluminateActions from './IlluminateActions';

import EnterpriseApiRoutes from '../../common/ApiRoutes';

const IlluminateStore: Store<void> = singletonStore('Illuminate', () => Reflux.createStore({
  listenables: [IlluminateActions],

  listPacks(bundleId: string): Promise<Packs> {
    const url = qualifyUrl(EnterpriseApiRoutes.Illuminate.listPacks(bundleId).url);
    const promise = fetch('GET', url);

    IlluminateActions.listPacks.promise(promise);

    return promise;
  },

  updatePacks(bundleId: string, packIds: string[], packData: Partial<Pack>): Promise<Packs> {
    const url = qualifyUrl(EnterpriseApiRoutes.Illuminate.updatePacks(bundleId).url);
    const promise = fetch('POST', url, { ids: packIds, ...packData });

    promise.catch((error) => {
      UserNotification.error(`Error Updating Pack: ${error.toString()}`, 'Unable to update Pack');
    });

    IlluminateActions.updatePacks.promise(promise);

    return promise;
  },

  listBundles(): Promise<Bundles> {
    const url = qualifyUrl(EnterpriseApiRoutes.Illuminate.listBundles().url);
    const promise = fetch('GET', url);

    IlluminateActions.listBundles.promise(promise);

    return promise;
  },

  updateBundle(bundleId: string, bundleData: Partial<Bundle>): Promise<Bundle> {
    const url = qualifyUrl(EnterpriseApiRoutes.Illuminate.updateBundle(bundleId).url);
    const promise = fetch('POST', url, bundleData);

    promise.catch((error) => {
      UserNotification.error(`Error Updating Bundle: ${error.toString()}`, 'Unable to update Bundle');
    });

    IlluminateActions.updateBundle.promise(promise);

    return promise;
  },

  deleteBundle(bundleId: string): Promise<Bundle> {
    const url = qualifyUrl(EnterpriseApiRoutes.Illuminate.deleteBundle(bundleId).url);
    const promise = fetch('DELETE', url);

    IlluminateActions.deleteBundle.promise(promise);

    return promise;
  },

  uploadBundle(bundleFile: File, nextPercentage: (number) => void): Promise<string> {
    const payload = new FormData();
    const xhr = new XMLHttpRequest();

    payload.append('file', bundleFile);

    const promise = new Promise<string>((resolve, reject) => {
      xhr.upload.onprogress = ({ loaded, total }) => {
        const percentage = Math.floor((loaded / total) * 1000) / 10;

        nextPercentage(percentage);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            const response = JSON.parse(xhr?.responseText)?.message ?? xhr.statusText;

            reject(new Error(response));
          }
        }
      };

      const SessionStore = StoreProvider.getStore('Session');
      const token = SessionStore.getSessionId();
      const buffer = Buffer.from(`${token}:session`);
      xhr.open('POST', qualifyUrl(EnterpriseApiRoutes.Illuminate.uploadBundle().url));
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.setRequestHeader('X-Requested-By', 'XMLHttpRequest');
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader('Authorization', `Basic ${buffer.toString('base64')}`);
      xhr.send(payload);
    });

    IlluminateActions.uploadBundle.promise(promise);

    return promise;
  },
}));

export { IlluminateActions, IlluminateStore };
export default IlluminateStore;
