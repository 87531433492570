import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SystemJob from 'components/systemjobs/SystemJob';
import ArchiveActions from 'archive/ArchiveActions';
import { DEFAULT_PAGE_SIZE } from 'archive/ArchiveStore';
import { Row, Col } from 'components/graylog';
import { CatalogPropType, JobsPropType } from 'archive/propTypes';

import type { Catalog, Jobs, Job } from '../types';

const StyledHr = styled.hr`
  margin-bottom: 5px; 
  margin-top: 10px;
`;
type Props = {
  catalog: Catalog,
  jobs: Jobs,
};

const ArchiveSystemJobs = ({ catalog, jobs }: Props) => {
  const [createJobs, setCreateJobs] = useState<Array<Job>>([]);
  const [restoreJobs, setRestoreJobs] = useState<Array<Job>>([]);
  const [rebuildJobs, setRebuildJobs] = useState<Array<Job>>([]);
  const { pagination: { page = 1, per_page: perPage = DEFAULT_PAGE_SIZE, query = null } } = catalog;

  useEffect(() => {
    const updateSystemJobs = (jobsList: Jobs) => {
      const newCreateJobs = [];
      const newRestoreJobs = [];
      const newRebuildJobs = [];

      Object.keys(jobsList).forEach((nodeId: string) => {
        jobsList[nodeId].jobs.forEach((job) => {
          switch (job.name) {
            case 'org.graylog.plugins.archive.job.ArchiveCreateSystemJob':
              newCreateJobs.push(job);
              break;
            case 'org.graylog.plugins.archive.job.ArchiveRestoreSystemJob':
              newRestoreJobs.push(job);
              break;
            case 'org.graylog.plugins.archive.job.BuildCatalogSystemJob':
              newRebuildJobs.push(job);
              break;
            default:
            // Ignore non-archive jobs.
          }
        });
      });

      setRebuildJobs(newRebuildJobs);

      if (newRestoreJobs.length > 0) {
        setRestoreJobs(newRestoreJobs);
      } else {
        // Check if there were jobs before. If so, list archives again to get the latest state.
        if (restoreJobs.length > 0) {
          ArchiveActions.searchPaginated(page, perPage, query);
        }

        setRestoreJobs([]);
      }

      if (newCreateJobs.length > 0) {
        setCreateJobs(newCreateJobs);
      } else {
        // Check if there were jobs before. If so, list archives again to get the latest state.
        if (createJobs.length > 0) {
          ArchiveActions.searchPaginated(page, perPage, query);
        }

        setCreateJobs([]);
      }
    };

    if (jobs) {
      updateSystemJobs(jobs);
    }
  }, [jobs, catalog, page, perPage, query, createJobs.length, restoreJobs.length]);

  const systemJobs = [].concat(restoreJobs, createJobs, rebuildJobs)
    .map((job: Job) => (<SystemJob key={job.id} job={job} />));

  if (systemJobs.length <= 0) {
    return null;
  }

  return (
    <Row key="system-job-progress" className="content">
      <Col md={12}>
        <h2>Job Progress</h2>
        <StyledHr />
        {systemJobs}
      </Col>
    </Row>
  );
};

ArchiveSystemJobs.propTypes = {
  catalog: CatalogPropType,
  jobs: JobsPropType,
};

ArchiveSystemJobs.defaultProps = {
  catalog: {
    archives: undefined,
    archivesContext: {},
    backendsContext: {},
    pagination: {
      page: 1,
      total: 0,
      count: 0,
      per_page: DEFAULT_PAGE_SIZE,
      query: '',
    },
  },
  jobs: {},
};

export default ArchiveSystemJobs;
