import Reflux from 'reflux';
import fetch from 'logic/rest/FetchProvider';
import type { Store } from 'stores/StoreTypes';
import { singletonStore } from 'views/logic/singleton';
import { qualifyUrl } from 'util/URLUtils';

import WatchlistActions, { WatchlistUpdateSuccess } from '../actions/WatchlistActions';

const apiPrefix = '/plugins/org.graylog.plugins.watchlist/watchlist';
export const ApiRoutes = {
  WatchlistApiController: {
    update: () => ({ url: `${apiPrefix}/add` }),
    delete: () => ({ url: `${apiPrefix}/remove` }),
    checkStatusForFields: () => ({ url: `${apiPrefix}/checkFieldValues` }),
  },
};

const WatchlistStore: Store<void> = singletonStore(
  'enterprise.Watchlist',
  () => Reflux.createStore({
    listenables: [WatchlistActions],

    update(watchlistFieldType: string, value: string): Promise<WatchlistUpdateSuccess> {
      const url = qualifyUrl(ApiRoutes.WatchlistApiController.update().url);
      const promise = fetch('POST', url, { type: watchlistFieldType, values: [value] })
        .then(() => ({ payload: { watchlistFieldType, value } }));

      WatchlistActions.update.promise(promise);

      return promise;
    },
    delete(watchlistFieldType: string, value: string): Promise<WatchlistUpdateSuccess> {
      const url = qualifyUrl(ApiRoutes.WatchlistApiController.delete().url);
      const promise = fetch('POST', url, { type: watchlistFieldType, values: [value] })
        .then(() => ({ payload: { watchlistFieldType, value } }));

      WatchlistActions.delete.promise(promise);

      return promise;
    },
  }),
);

export { WatchlistActions, WatchlistStore };
export default WatchlistStore;
