// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2RQjSsVCMyBzhCFd9t-cua {\n    border: 1px outset #aaa;\n    font-size: 14px;\n    margin-bottom: 5px;\n    padding: 3em;\n    width: 95%;\n}\n", "",{"version":3,"sources":["webpack://./src/web/report/common/PaperSheet.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,UAAU;AACd","sourcesContent":[":local(.paperSheet) {\n    border: 1px outset #aaa;\n    font-size: 14px;\n    margin-bottom: 5px;\n    padding: 3em;\n    width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paperSheet": "_2RQjSsVCMyBzhCFd9t-cua"
};
export default ___CSS_LOADER_EXPORT___;
