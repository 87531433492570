import * as React from 'react';
import * as Immutable from 'immutable';

import { Link } from 'components/graylog/router';
import Routes from 'routing/Routes';
import RolesCell from 'components/permissions/RolesCell';

import Team from '../../security/logic/Team';
import UsersCell from '../../security/components/teams-overview/UsersCell';

type Props = {
  team: Team,
  rolesContext: Immutable.Map<string, { title: string }>,
  usersContext: Immutable.Map<string, { title: string }>,
};

const SyncedTeamsOverviewItem = ({
  team,
  team: {
    id,
    name,
    roles: teamsRolesIds,
  },
  rolesContext = Immutable.Map(),
  usersContext = Immutable.Map(),
}: Props) => {
  const teamRolesNames = teamsRolesIds.map((roleId) => {
    return rolesContext.get(roleId)?.title ?? `${roleId} (Name not found)`;
  }).toSet();

  return (
    <tr key={id}>
      <td className="limited">
        <Link to={Routes.pluginRoute('SYSTEM_TEAMS_TEAMID')(id)}>
          {name}
        </Link>
      </td>
      <UsersCell team={team} usersContext={usersContext} />
      <RolesCell roles={teamRolesNames} />
    </tr>
  );
};

export default SyncedTeamsOverviewItem;
