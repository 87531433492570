import * as React from 'react';
import { createContext, useState, useEffect, useContext, Dispatch, SetStateAction } from 'react';

import { BundleContext } from './BundleContext';

import { IlluminateActions } from '../stores/IlluminateStore';
import { Pack, Packs } from '../types';

type PackContextValues = {
  packs: Packs,
  selectedPackIds: Array<Pack['pack_id']>,
  packsLoading: boolean,
  setSelectedPackIds: Dispatch<SetStateAction<string[]>>,
  updatePacks: (options: Partial<Pack>) => Promise<void>
}

const DEFAULT_PACK = [];

export const PackContext = createContext<PackContextValues>({
  packs: DEFAULT_PACK,
  selectedPackIds: DEFAULT_PACK,
  packsLoading: true,
  setSelectedPackIds: undefined,
  updatePacks: undefined,
});

const PackProvider = ({ children }: {children: React.ReactNode}) => {
  const { bundleVersion } = useContext(BundleContext);

  const [packs, setPacks] = useState<PackContextValues['packs']>(DEFAULT_PACK);
  const [selectedPackIds, setSelectedPackIds] = useState<PackContextValues['selectedPackIds']>(DEFAULT_PACK);
  const [loading, setLoading] = useState<PackContextValues['packsLoading']>(true);

  const updatePacks = (packOptions) => {
    return IlluminateActions.updatePacks(bundleVersion, selectedPackIds, packOptions).then((updatedPacks) => {
      const newPacks = packs.map((pack) => {
        return updatedPacks.filter((updatedPack) => updatedPack.pack_id === pack.pack_id).pop() ?? pack;
      });

      setPacks(newPacks);
    });
  };

  useEffect(() => {
    if (bundleVersion) {
      setLoading(true);

      IlluminateActions.listPacks(bundleVersion).then((packList) => {
        setLoading(false);
        setPacks(packList.filter((pack) => !pack.is_core));
      });
    }
  }, [bundleVersion]);

  return (
    <PackContext.Provider value={{ packsLoading: loading, packs, selectedPackIds, setSelectedPackIds, updatePacks }}>
      {children}
    </PackContext.Provider>
  );
};

export default PackProvider;
