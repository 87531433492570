import * as React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/graylog/router';
import { Alert } from 'components/graylog';
import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';
import type { UserJSON } from 'logic/users/User';

type Props = {
  currentUser: UserJSON,
}

const LicenseViolationNotification = ({ currentUser }: Props) => (
  <Alert bsStyle="danger" className="has-bm">
    <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise License Violation</strong></h4>
    <p>
      At least one term of your Graylog Enterprise license has been violated.{' '}
      {isPermitted(currentUser.permissions, 'licenses:create') ? (
        <span>
          Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
          more information or contact your Graylog account manager.
        </span>
      ) : (
        <span>Contact your Graylog account manager for more information.</span>
      )}
    </p>
  </Alert>
);

LicenseViolationNotification.propTypes = {
  currentUser: PropTypes.object.isRequired,
};

export default LicenseViolationNotification;
