import TeamsOverviewPage from './pages/TeamsOverviewPage';
import TeamDetailsPage from './pages/TeamDetailsPage';
import TeamEditPage from './pages/TeamEditPage';
import TeamCreatePage from './pages/TeamCreatePage';
import UserTeamsAssignment from './components/user/UserTeamsAssignment';
import RoleTeamsAssignment from './components/roles/RoleTeamsAssignment';

export default {
  teams: {
    UserTeamsAssignment,
    RoleTeamsAssignment,
  },
  routes: [
    { path: '/system/teams', component: TeamsOverviewPage, permissions: 'teams:read' },
    { path: '/system/teams/new', component: TeamCreatePage, permissions: 'teams:create' },
    { path: '/system/teams/:teamId', component: TeamDetailsPage, permissions: 'teams:read' },
    { path: '/system/teams/:teamId/edit', component: TeamEditPage, permissions: 'teams:update' },
  ],
};
