import React, { useEffect, useState } from 'react';
import naturalSort from 'javascript-natural-sort';
import styled from 'styled-components';

import connect from 'stores/connect';
import Routes from 'routing/Routes';
import { Button, Col, Row } from 'components/graylog';
import { Input } from 'components/bootstrap';
import { Select, Spinner, ReadOnlyFormGroup } from 'components/common';
import DocumentationLink from 'components/support/DocumentationLink';
import ChecksumTypes from 'archive/logic/ChecksumTypes';
import CompressionTypes from 'archive/logic/CompressionTypes';
import BackendTypes from 'archive/logic/BackendTypes';
import history from 'util/History';
import ArchiveConfigStreamSelectionForm from 'archive/components/ArchiveConfigStreamSelectionForm';
import { ArchiveConfigurationActions } from 'archive/ArchiveConfigurationStore';
import ArchiveBackendsStore, { ArchiveBackendsActions, ArchiveBackendsStoreState } from 'archive/ArchiveBackendsStore';
import type { Backend, Pagination, ArchiveConfiguration } from 'archive/types';
import AppConfig from 'util/AppConfig';
import { BackendsPropType } from 'archive/propTypes';
import { Store } from 'stores/StoreTypes';

const isCloud = AppConfig.isCloud();

const DEFAULT_BACKEND_BACKEND = 'Shared FS';

const StyledForm = styled.form`
  margin-top: 10px 
`;

type Props = {
  backends: {
    backends: Array<Backend>,
    pagination: Pagination,
  },
};

const ArchiveConfigForm = ({ backends }: Props) => {
  const [configuration, setConfiguration] = useState<ArchiveConfiguration | undefined>();

  useEffect(() => {
    ArchiveConfigurationActions.getConfig().then((config) => setConfiguration(config));

    // TODO: Magic pagination number - backend selection should probably be a search form instead of a drop-down
    ArchiveBackendsActions.listBackends(1, 1000);
  }, []);

  if (!configuration || !backends) {
    return <Spinner />;
  }

  const _updateConfig = (fieldName: string, value: any) => {
    const updatedConfig = { ...configuration, [fieldName]: value };
    setConfiguration(updatedConfig);
  };

  const _onInputUpdate = (fieldName) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      _updateConfig(fieldName, e.target.value);
    };
  };

  const _onChecksumTypeSelect = (selection: string) => {
    if (selection === '') {
      return;
    }

    _updateConfig('segment_checksum_type', selection);
  };

  const _onCompressionTypeSelect = (selection: string) => {
    if (selection === '') {
      return;
    }

    _updateConfig('segment_compression_type', selection);
  };

  const _onBackendTypeSelect = (selection: string) => {
    if (selection === '') {
      return;
    }

    _updateConfig('backend_id', selection);
  };

  const _onExcludedStreamsChange = (excludedStreams: Array<string>) => {
    _updateConfig('excluded_streams', excludedStreams);
  };

  const _saveConfiguration = (event) => {
    event.preventDefault();

    ArchiveConfigurationActions.saveConfig(configuration).then(() => {
      history.replace(Routes.pluginRoute('SYSTEM_ARCHIVES'));
    });
  };

  const sortedBackends = backends.backends.map((backend) => {
    return { value: backend.id, label: `${backend.title} (${BackendTypes.getBackendType(backend.settings.type).label})` };
  }).sort((a, b) => naturalSort(a.label.toLowerCase(), b.label.toLowerCase()));

  const fileSystemBackend = backends.backends.find((backend) => {
    return backend.title === DEFAULT_BACKEND_BACKEND;
  });
  const compressionTypesHelpText = (
    <span>
      Compression method to apply on archive files. Read the{' '}
      <DocumentationLink page="archiving/setup.html#compression-type" text="documentation" />{' '}
      for more information on the different options.
    </span>
  );
  const checksumTypesHelpText = (
    <span>
      Checksum method to apply on archive files. Read the{' '}
      <DocumentationLink page="archiving/setup.html#checksum-type" text="documentation" />{' '}
      for more information on the different options.
    </span>
  );

  const sortedCompressionTypes = CompressionTypes.compressionTypes.sort((t1, t2) => naturalSort(t1.label.toLowerCase(), t2.label.toLowerCase()));

  return (
    <div>
      <h2>Archives configuration</h2>
      <StyledForm className="form form-horizontal" onSubmit={_saveConfiguration}>
        <fieldset>
          {isCloud && <ReadOnlyFormGroup label="Backend" value={fileSystemBackend?.title} />}
          {!isCloud && (
          <>
            <Input id="backend-select"
                   label="Backend"
                   required
                   autoFocus
                   help="In which backend should the archives be stored?"
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <Select placeholder="Select Backend"
                      clearable={false}
                      options={sortedBackends}
                      matchProp="label"
                      onChange={_onBackendTypeSelect}
                      value={configuration.backend_id} />
            </Input>
            <Input type="text"
                   id="config-max-segment-size"
                   label="Max Segment Size"
                   onChange={_onInputUpdate('max_segment_size')}
                   value={configuration.max_segment_size}
                   help={<span>Maximum size for each message segment in <strong>bytes</strong>.</span>}
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9"
                   required />
            <Input id="compresion-type-select"
                   label="Compression Type"
                   help={compressionTypesHelpText}
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <Select placeholder="Select Compression Type"
                      options={sortedCompressionTypes}
                      matchProp="label"
                      onChange={_onCompressionTypeSelect}
                      value={CompressionTypes.getCompressionType(configuration.segment_compression_type)?.value} />
            </Input>
            <Input id="checksum-type-select"
                   label="Checksum Type"
                   help={checksumTypesHelpText}
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9">
              <Select placeholder="Select Checksum Type"
                      options={ChecksumTypes.checksumTypes}
                      matchProp="label"
                      onChange={_onChecksumTypeSelect}
                      value={ChecksumTypes.getChecksumType(configuration.segment_checksum_type)?.value} />
            </Input>
            <Input type="text"
                   id="config-restore-index-batch-size"
                   label="Restore index batch size"
                   onChange={_onInputUpdate('restore_index_batch_size')}
                   value={configuration.restore_index_batch_size}
                   help="When restoring an archive, what batch size should be used to re-import the data?"
                   labelClassName="col-sm-3"
                   wrapperClassName="col-sm-9" />
          </>
          )}
          <Input id="config-excluded-streams"
                 label="Streams to archive"
                 help="Select streams that should be included in the archive. New streams will be archived by default."
                 labelClassName="col-sm-3"
                 wrapperClassName="col-sm-9">
            <ArchiveConfigStreamSelectionForm excludedStreams={configuration.excluded_streams}
                                              updateExcludedStreams={_onExcludedStreamsChange} />
          </Input>
          <Row>
            <Col smOffset={3} sm={9}>
              <Button type="submit" bsStyle="success">Update configuration</Button>
            </Col>
          </Row>
        </fieldset>
      </StyledForm>
    </div>
  );
};

ArchiveConfigForm.propTypes = {
  backends: BackendsPropType,
};

ArchiveConfigForm.defaultProps = {
  backends: undefined,
};

export default connect(
  ArchiveConfigForm,
  {
    archiveBackends: ArchiveBackendsStore as Store<ArchiveBackendsStoreState>,
  },
  ({ archiveBackends, ...otherProps }) => ({
    backends: archiveBackends.backends,
    ...otherProps,
  }),
);
