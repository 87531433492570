// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2i1-hgxSI4y0jdGOt1rPrt {\n    color: #a6a6a6;\n    font-size: 0.8em;\n    margin: 10px auto 0 auto;\n    text-align: center;\n    width: 75%;\n}\n\n@media print {\n    ._2i1-hgxSI4y0jdGOt1rPrt {\n        page-break-before: avoid;\n        page-break-inside: avoid;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/web/report/common/Caption.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,wBAAwB;IACxB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI;QACI,wBAAwB;QACxB,wBAAwB;IAC5B;AACJ","sourcesContent":[":local(.caption) {\n    color: #a6a6a6;\n    font-size: 0.8em;\n    margin: 10px auto 0 auto;\n    text-align: center;\n    width: 75%;\n}\n\n@media print {\n    :local(.caption) {\n        page-break-before: avoid;\n        page-break-inside: avoid;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": "_2i1-hgxSI4y0jdGOt1rPrt"
};
export default ___CSS_LOADER_EXPORT___;
