import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';

import connect from 'stores/connect';
import { Button, FormGroup, InputGroup, FormControl, HelpBlock } from 'components/graylog';
import ColorPickerPopover from 'components/common/ColorPickerPopover';
import Icon from 'components/common/Icon';
import StoreProvider from 'injection/StoreProvider';
import ObjectUtils from 'util/ObjectUtils';
import { getValueFromInput } from 'util/FormsUtils';
import { isPermitted } from 'util/PermissionsMixin';
import { Store } from 'stores/StoreTypes';

const CurrentUserStore = StoreProvider.getStore('CurrentUser');

const ColorPickerPreview = styled.span<{ color: string }>(({ color }) => css`
  background-color: ${color};
  width: 10px;
  height: 10px;
  display: inherit;
  margin-right: 5px;
  margin-top: 3px;
`);

const DEFAULT = {
  badge_enable: false,
  badge_color: '#689f38',
  badge_text: '',
};

export type BadgeConfig = {
  badge_enable: boolean,
  badge_color: string,
  badge_text: string,
};

type Props = {
  config: BadgeConfig,
  updateConfig: (BadgeConfig) => void,
  currentUser?: {
    permissions?: string[],
  },
};

const HeaderBadgeConfiguration = ({ config, updateConfig, currentUser }: Props) => {
  const [badgeConfig, setBadgeConfig] = useState(DEFAULT);
  const [hasChanged, setHasChanged] = useState(false);
  const [warning, setWarning] = useState<Partial<BadgeConfig>>({});

  useEffect(() => {
    if (config) {
      setBadgeConfig(config);
    }
  }, [config]);

  const _saveConfig = useCallback(() => {
    setHasChanged(false);
    updateConfig(badgeConfig);
  }, [badgeConfig, updateConfig]);

  const handleColorChange = useCallback((color, _, hidePopover) => {
    setHasChanged(!config || config.badge_color !== color);
    const newBadgeConfig = { ...badgeConfig, badge_color: color };
    setBadgeConfig(newBadgeConfig);

    hidePopover();
  }, [badgeConfig, config]);

  const validate = () => {
    if (badgeConfig.badge_text.length > 5) {
      warning.badge_text = 'Should be maximum 5 characters long';
    } else {
      warning.badge_text = null;
    }

    setWarning(warning);
  };

  const _onUpdate = (field) => {
    return (value) => {
      const update: typeof badgeConfig = ObjectUtils.clone(badgeConfig);

      if (typeof value === 'object') {
        update[field] = getValueFromInput(value.target);
      } else {
        update[field] = value;
      }

      setHasChanged(!isEqual(config, update));
      setBadgeConfig(update);
      validate();
    };
  };

  const isDisabled = !isPermitted(currentUser.permissions, 'clusterconfigentry:edit');

  return (
    <div>
      <FormGroup validationState={warning.badge_text ? 'warning' : null}>
        <InputGroup>
          <InputGroup.Addon>
            <input type="checkbox"
                   id="badge-enable"
                   data-testid="badge-enable"
                   checked={badgeConfig.badge_enable}
                   onChange={_onUpdate('badge_enable')}
                   disabled={isDisabled} />
          </InputGroup.Addon>

          <FormControl type="text"
                       id="badge-text"
                       data-testid="badge-text"
                       label="Badge Text"
                       value={badgeConfig.badge_text}
                       onChange={_onUpdate('badge_text')}
                       help={warning.badge_text ? warning.badge_text : 'The text of the badge. Not more than five characters.'}
                       disabled={isDisabled} />

          <InputGroup.Button>
            <ColorPickerPopover id="badge-color"
                                placement="left"
                                color={badgeConfig.badge_color}
                                triggerNode={(
                                  <Button disabled={isDisabled}>
                                    <ColorPickerPreview color={badgeConfig.badge_color} />
                                    <Icon name="paint-brush" /> Color
                                  </Button>
                                )}
                                onChange={handleColorChange} />
          </InputGroup.Button>
        </InputGroup>

        <HelpBlock>{warning.badge_text ? warning.badge_text : 'The text of the badge. Not more than five characters recommended.'}</HelpBlock>

        {!isDisabled && <Button bsSize="xsmall" bsStyle="info" onClick={_saveConfig} disabled={!hasChanged}>Update Badge</Button>}
      </FormGroup>
    </div>
  );
};

HeaderBadgeConfiguration.propTypes = {
  config: PropTypes.exact({
    /* eslint-disable react/no-unused-prop-types */
    badge_text: PropTypes.string.isRequired,
    badge_color: PropTypes.string.isRequired,
    badge_enable: PropTypes.bool.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  }),
  warning: PropTypes.shape({
    badge_text: PropTypes.string,
  }),
  updateConfig: PropTypes.func,
};

HeaderBadgeConfiguration.defaultProps = {
  updateConfig: () => {},
  currentUser: { permissions: undefined },
  config: {
    badge_text: '',
    badge_color: '#689f38',
    badge_enable: false,
  },
  warning: {
    badge_text: null,
  },
};

type CurrentUserStoreState = {
  currentUser: {
    permissions: string[],
  },
};

export default connect(HeaderBadgeConfiguration,
  { currentUser: CurrentUserStore as Store<CurrentUserStoreState> },
  ({ currentUser }): Pick<Props, 'currentUser'> => ({
    currentUser: currentUser?.currentUser ?? undefined,
  }));
