import { useEffect, useState } from 'react';

import { PaginatedRoles } from 'actions/roles/AuthzRolesActions';
import AuthzRolesDomain from 'domainActions/roles/AuthzRolesDomain';
import Role from 'logic/roles/Role';

function useRoles(): { isLoading: boolean; roles: Role[] } {
  const [paginatedRoles, setPaginatedRoles] = useState<PaginatedRoles | undefined>();

  useEffect(() => {
    const getUnlimited = { page: 1, perPage: 0, query: '' };

    AuthzRolesDomain.loadRolesPaginated(getUnlimited).then(setPaginatedRoles);
  }, []);

  if (!paginatedRoles) {
    return {
      isLoading: true,
      roles: [],
    };
  }

  const roles = paginatedRoles.list.toArray();

  return {
    isLoading: false,
    roles,
  };
}

export default useRoles;
