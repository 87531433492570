import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/graylog/router';
import { Alert, Col, Row } from 'components/graylog';
import { IfPermitted } from 'components/common';
import Routes from 'routing/Routes';
import withHideOnCloud from 'util/conditional/withHideOnCloud';

import { isValidLicense } from '../../logic/license';

class ReportLicenseStatus extends React.Component {
  static propTypes = {
    licenseStatus: PropTypes.shape({
      status: PropTypes.object,
      missing: PropTypes.bool.isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    message: PropTypes.string,
    colWidth: PropTypes.number,
    rowClass: PropTypes.string,
    summary: PropTypes.bool,
  };

  static defaultProps = {
    colWidth: 12,
    rowClass: 'content',
    message: undefined,
    summary: false,
  };

  renderStatus = () => {
    return (
      <Alert bsStyle="danger">
        <h4 style={{ fontWeight: 'bold', marginBottom: 5 }}>Reporting is disabled</h4>
        <p>
          {this.props.message || 'Report scheduling is disabled because there is no valid license.'}
        </p>
        <IfPermitted permissions="licenses:create">
          <p>
            See <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for details.
          </p>
        </IfPermitted>
      </Alert>
    );
  };

  render() {
    if (isValidLicense(this.props.licenseStatus)) {
      return null;
    }

    const { summary, rowClass, colWidth } = this.props;

    if (summary) {
      return this.renderStatus();
    }

    return (
      <Row className={rowClass}>
        <Col md={colWidth}>
          {this.renderStatus()}
        </Col>
      </Row>
    );
  }
}

export default withHideOnCloud(ReportLicenseStatus);
