import * as React from 'react';
import PropTypes from 'prop-types';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { ReactNode, useMemo, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import Icon from 'components/common/Icon';
import { Alert, ProgressBar } from 'components/graylog';

const StyledIcon = styled(Icon)(({ theme }) => css`
  margin-right: ${theme.spacings.xs};
`);

type Props = DropzoneOptions & {
  message?: string | ReactNode,
  uploadAction: (file: File, uploadPercentageCallback: (number) => void) => Promise<string>,
  onComplete: (bundleVersion: string) => void,
}

const DragDropUpload = ({ accept, maxFiles, uploadAction, message, onComplete }: Props) => {
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const [rejectedFileMessage, setRejectedFileMessage] = useState<string>();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    accept,
    maxFiles,
    onDrop: (filesToUpload, fileRejections) => {
      setRejectedFileMessage(fileRejections?.[0]?.errors[0]?.message);
      console.log(filesToUpload);

      if (filesToUpload.length) {
        uploadAction(filesToUpload[0], setUploadPercentage)
          .then((bundleVersion) => {
            onComplete(bundleVersion);
          }).catch((uploadError) => {
            setRejectedFileMessage(uploadError.message);
          });
      }
    },
  });

  const { colors } = useTheme();

  const baseStyle = useMemo(() => ({
    borderColor: colors.variant.light.default,
    backgroundColor: colors.variant.lightest.default,
    color: colors.variant.dark.default,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    outline: 'none',
    textAlign: 'center',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  } as const), [colors]);

  const activeStyle = useMemo(() => ({
    borderColor: colors.variant.light.info,
    backgroundColor: colors.variant.lightest.info,
  } as const), [colors]);

  const acceptStyle = useMemo(() => ({
    borderColor: colors.variant.light.success,
    backgroundColor: colors.variant.lightest.success,
  } as const), [colors]);

  const rejectStyle = useMemo(() => ({
    borderColor: colors.variant.light.danger,
    backgroundColor: colors.variant.lightest.danger,
  } as const), [colors]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    baseStyle,
    activeStyle,
    acceptStyle,
    rejectStyle,
  ]);

  const acceptedFileName = acceptedFiles?.[0]?.name;

  const SuccessAlert = useMemo(() => ((acceptedFileName && !rejectedFileMessage) ? (
    <Alert bsStyle="success" key={acceptedFileName}>
      <StyledIcon name="check-circle" />{acceptedFileName} will begin uploading shortly...
    </Alert>
  ) : null), [acceptedFileName, rejectedFileMessage]);

  const ErrorAlert = useMemo(() => (rejectedFileMessage ? (
    <Alert bsStyle="danger" key={rejectedFileMessage}>
      <StyledIcon name="times-circle" />{rejectedFileMessage}. Please try again.
    </Alert>
  ) : null), [rejectedFileMessage]);

  const roundedPercentage = rejectedFileMessage ? -1 : Math.round(uploadPercentage);

  return (
    <div>
      {ErrorAlert}

      {SuccessAlert || (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} data-testid="drag-drop-upload" />
          {message}
        </div>
      )}

      {roundedPercentage > 0 && (
      <ProgressBar bars={[{
        animated: true,
        striped: true,
        value: roundedPercentage,
        bsStyle: 'info',
        label: roundedPercentage === 100 ? 'Completed Upload! Parsing Packs.' : `${roundedPercentage}%`,
      }]} />
      )}
    </div>
  );
};

DragDropUpload.propTypes = {
  /* The value must be a comma-separated list of unique content type specifiers: https://react-dropzone.js.org/#section-accepting-specific-file-types */
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /* By providing maxFiles prop you can limit how many files the dropzone accepts: https://react-dropzone.js.org/#section-accepting-specific-number-of-files */
  maxFiles: PropTypes.number,
  /* Your component's Action Store function that will upload the file to the API: see Enterprise's IlluminateStore.tsx */
  uploadAction: PropTypes.func.isRequired,
  /* A string or ReactNode containing the content that will appear before uploading */
  message: PropTypes.node,
  /* The function that will be called when the upload has been completed */
  onComplete: PropTypes.func.isRequired,
};

DragDropUpload.defaultProps = {
  accept: undefined,
  maxFiles: undefined,
  message: (<span>Drag and Drop your files here.</span>),
};

export default DragDropUpload;
