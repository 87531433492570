const summaryTemplate = {
    authBasic: {
        summary: '[{gim_event_type}] {user_name} ({event_outcome})'
    },
    authPrimary: {
        summary: '[{gim_event_type}] {application_name} - {source_reference} - {user_name}'
    },
    authExtended: {
        summary: '[{gim_event_type}] {application_name} - {source_reference} - {user_domain} {user_name} ({event_outcome})'
    },
    networkConnection: {
        summary: '[{gim_event_type}] {source_ip}:{source_port} {destination_ip}:{destination_port} [{network_transport}]'
    },
    Alert: {
        summary: '[{gim_event_type}] {source_ip} - {destination_ip} {destination_port} - {alert_signature}'
    },
    default: {
        summary: '[{gim_event_type}] {source}'
    }
};
const indicatorTemplate = {
    eventOutcome: {
        success: ['success'],
        danger: ['failure'],
        indicator: 'event_outcome',
        defaultIndicator: 'warning'
    },
    alertPrimary: {
        warning: ['high'],
        danger: ['critical'],
        indicator: 'alert_severity',
        defaultIndicator: 'info'
    }
};
const eventTypeCategories = {
    authentication: {
        logon: {
            summary: '[{gim_event_type}] {application_name} - {source_reference} - {user_domain} {user_name} - {destination_reference} ({event_outcome})',
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'credential validation': {
            summaryTemplate: summaryTemplate.authExtended,
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'kerberos request': {
            summaryTemplate: summaryTemplate.authPrimary,
            indicatorTemplate: indicatorTemplate.eventOutcome
        },
        'access notice': {
            summaryTemplate: summaryTemplate.authPrimary
        },
        'access policy': {
            summaryTemplate: summaryTemplate.authPrimary
        },
        logoff: {
            summaryTemplate: summaryTemplate.authPrimary
        },
        default: {
            summaryTemplate: summaryTemplate.authBasic,
            indicatorTemplate: indicatorTemplate.eventOutcome,
            defaultIndicator: 'info'
        }
    },
    network: {
        'network connection': {
            summaryTemplate: summaryTemplate.networkConnection
        },
        'network routing': {
            summaryTemplate: summaryTemplate.default
        },
        flow: {
            summaryTemplate: summaryTemplate.networkConnection
        },
        default: {
            summary: '[{gim_event_type}] {source_ip} - {destination_ip}'
        }
    },
    iam: {
        'object create': {
            summaryTemplate: summaryTemplate.default
        },
        'object delete': {
            summaryTemplate: summaryTemplate.default
        },
        'object modify': {
            summaryTemplate: summaryTemplate.default
        },
        'object disable': {
            summaryTemplate: summaryTemplate.default
        },
        'object enable': {
            summaryTemplate: summaryTemplate.default
        },
        information: {
            summaryTemplate: summaryTemplate.default
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    alert: {
        'ids alert': {
            summaryTemplate: summaryTemplate.Alert,
            indicatorTemplate: indicatorTemplate.alertPrimary
        },
        'network alert': {
            summaryTemplate: indicatorTemplate.alertPrimary,
            indicatorTemplate: indicatorTemplate.alertPrimary
        },
        'host alert': {
            summaryTemplate: indicatorTemplate.alertPrimary,
            indicatorTemplate: indicatorTemplate.alertPrimary
        },
        'dlp alert': {
            summaryTemplate: indicatorTemplate.alertPrimary,
            indicatorTemplate: indicatorTemplate.alertPrimary
        },
        default: {
            summaryTemplate: indicatorTemplate.alertPrimary,
            indicatorTemplate: indicatorTemplate.alertPrimary
        }
    },
    'name resolution': {
        'dns request': {
            summaryTemplate: summaryTemplate.default
        },
        'dns transaction': {
            summaryTemplate: summaryTemplate.default
        },
        'dns answer': {
            summaryTemplate: summaryTemplate.default
        },
        'ddns update': {
            summaryTemplate: summaryTemplate.default
        },
        error: {
            summaryTemplate: summaryTemplate.default,
            defaultIndicator: 'warning'
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    database: {
        query: {
            summaryTemplate: summaryTemplate.default
        },
        update: {
            summaryTemplate: summaryTemplate.default
        },
        add: {
            summaryTemplate: summaryTemplate.default
        },
        delete: {
            summaryTemplate: summaryTemplate.default
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    },
    endpoint: {
        process: {
            summaryTemplate: summaryTemplate.default
        },
        ports: {
            summaryTemplate: summaryTemplate.default
        },
        filesystem: {
            summaryTemplate: summaryTemplate.default
        },
        service: {
            summaryTemplate: summaryTemplate.default
        },
        configuration: {
            summaryTemplate: summaryTemplate.default
        },
        audit: {
            summaryTemplate: summaryTemplate.default
        },
        pipe: {
            summaryTemplate: summaryTemplate.default
        },
        wmi: {
            summaryTemplate: summaryTemplate.default
        },
        performance: {
            summaryTemplate: summaryTemplate.default
        },
        default: {
            summaryTemplate: summaryTemplate.default
        }
    }
};
export { summaryTemplate, indicatorTemplate, eventTypeCategories };
