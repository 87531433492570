// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2p-LNTJ5Iu9a0p8sIRCxkL {\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/web/event-correlation/EventsLicenseStatus.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[":local(.licenseStatusTitle) {\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"licenseStatusTitle": "_2p-LNTJ5Iu9a0p8sIRCxkL"
};
export default ___CSS_LOADER_EXPORT___;
